import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DatasheetService {

  constructor(protected http: HttpClient) { }

  import(formData): Observable<any> {
    return this.http.post('/datasheet/import', formData);
  }

  list(): Observable<any> {
    return this.http.get<any>('/datasheet');
  }
}
