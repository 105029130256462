import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import * as urlJoin from 'url-join';

import { environment } from '../../environments/environment';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let secureReq;
    if (req.url.includes('asset')) {
      secureReq = req.clone();
    } else {
      secureReq = req.clone({
        url: urlJoin(environment.api_url, req.url)
      });
    }
    return next.handle(this.hasBaseUrl(req) ? req : secureReq);
  }

  hasBaseUrl(req: HttpRequest<any>) {
    return req.url.startsWith(environment.api_url);
  }
}
