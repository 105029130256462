import { GetStatus } from './../store/actions/auth.actions';
import { isEqual } from 'lodash';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from '../store/app.states';

@Injectable()
export class AclService {
  aclState = this.store.select(selectAuthState)
  .pipe(
    distinctUntilChanged((prev: any, curr: any) => {
      const authEqual = prev.isAuthenticated === curr.isAuthenticated;
      let rolesEqual = false;
      if (authEqual && curr.user) {
        rolesEqual = isEqual(prev.user.roles, curr.user.roles);
      }
      return authEqual && rolesEqual;
    }),
    map(state => {
      if (state.isAuthenticated) {
        return new AclState(true, state.user.roles || [], state.user._id);
      } else {
        return new AclState(false, [], null);
      }
    }),
    shareReplay(1)
  );

  constructor(protected store: Store<AppState>) {
    store.dispatch(new GetStatus());
  }
}

export class AclState {

  constructor(private authenticated: boolean, private permissions: string[], private userId: string) {}

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  hasPermission(requestedPermission: string) {
    return requestedPermission && !!this.permissions.find((p) => p.toLowerCase() === requestedPermission.toLowerCase());
  }

  checkId(id: string) {
    return this.userId === id;
  }
}
