import { Component, Directive, forwardRef, Renderer, Renderer2, ElementRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
    // tslint:disable-next-line: component-selector
    selector: 'input[customPrefix]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomItemInput),
            multi: true
        }
    ],
    // tslint:disable-next-line: no-host-metadata-property
    host: {
        '(input)': 'handleInput($event.target.value)'
    }
})

export class CustomItemInput implements ControlValueAccessor {
    /**
     *
     */
    constructor(protected renderer: Renderer2, protected elementRef: ElementRef) {
        this.writeValue(null);
    }
    // tslint:disable-next-line: variable-name
    protected _customPrefix = 'VX-';
    // tslint:disable-next-line: no-input-rename
    @Input('customPrefix')
    set prefixCustomItem(prefix) {
        const newValue = this.val.replace(this._customPrefix, prefix);
        this._customPrefix = prefix;
        this.value = newValue;
    } // prefisso per gli item custom - this is the updated value that the class accesses
    get prefixCustomItem() {
        return this._customPrefix;
    }

    val = '';
    set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', val);
        this.val = val;
        this.onChange(val);
        this.onTouch(val);
    }
    onChange: any = () => { };
    onTouch: any = () => { };

    // this method sets the value programmatically
    writeValue(value: string) {
        if (value && value.startsWith(this.prefixCustomItem)) {

            this.value = value;
        }
        if (!value) {
            this.value = this.prefixCustomItem;
        }
        this.value = this.val;
    }
    // upon UI element value changes, this method gets triggered
    registerOnChange(fn: any) {
        this.onChange = fn;
    }
    // upon touching the element, this method gets triggered
    registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    handleInput(value) {
        if (value && value.startsWith(this.prefixCustomItem)) {
            this.value = value;
        }
        if (!value) {
            this.value = this.prefixCustomItem;
        }
        this.value = this.val;
    }
}
