import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VpDetailService {

  constructor(
    protected http: HttpClient) { }

  list(): Observable<any[]> {
    const api = '/vp-details';
    return this.http.get<any[]>(api);
  }

  create(name: string, languages: any, importFile?: any): Observable<any> {
    const formData = new FormData();
    formData.append('data', JSON.stringify({name, languages}));
    if (importFile) {
      formData.append('picture', importFile);
    }
    return this.http.post('/vp-details', formData);
  }

  update(id: string, name: string, languages: any, importFile?: any): Observable<any> {
    const formData = new FormData();
    formData.append('data', JSON.stringify({name, languages}));
    if (importFile) {
      formData.append('picture', importFile);
    }
    return this.http.put(`/vp-details/${id}`, formData);
  }
}
