import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vp-edit-dialog',
  templateUrl: './vp-edit-dialog.component.html'
})
export class VPEditDialogComponent implements OnInit {
  vpForm = this.fb.group({
    name: [''],
    languages: this.fb.group({
      en: '',
      it: '',
      sp: '',
      de: '',
      ru: ''
    }),
    importFile: [undefined]
  });
  constructor(
    public dialogRef: MatDialogRef<VPEditDialogComponent>,
    protected fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    const nameControl = this.vpForm.get('name');
    if (this.data) {
      nameControl.disable();
      this.vpForm.patchValue(this.data);
    } else {
      nameControl.setValidators(Validators.required);
      const fileControl = this.vpForm.get('importFile');
      fileControl.setValidators(Validators.required);
    }
  }



  onSave() {
    if (this.vpForm.valid) {
      const { name, languages, importFile } = this.vpForm.value;
      const data = {
        name,
        languages,
        picture: importFile && importFile.files && importFile.files[0] ? importFile.files[0] : null
      }
      this.dialogRef.close(data);
    }
  }

  onDismiss(): void {
    this.dialogRef.close();
  }
}
