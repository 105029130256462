import { Component, OnInit } from '@angular/core';
import { UserService } from '../users.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';

const PasswordValidation = [
  Validators.required,
  Validators.minLength(6),
  Validators.maxLength(24),
];

@Component({
  selector: 'app-me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.scss']
})
export class MeComponent implements OnInit {
  user$ = this.srv.me();
  ok = false;
  error = false;

  form = this.fb.group({
    username: ['', Validators.required],
    name: ['', Validators.required],
    oldPassword: ['', Validators.required],
    newPassword: ['', PasswordValidation],
    confirmPassword: ['', PasswordValidation]
  });

  constructor(protected srv: UserService,
    protected fb: FormBuilder) { }

  ngOnInit() {
    this.user$.subscribe(user => this.form.patchValue(user));
    this.form.get('confirmPassword').setValidators(PasswordValidation.concat([this.confirmValidator.bind(this)]));
  }

  save() {
    this.ok = false;
    this.error = false;
    const { valid, value } = this.form;
    if (valid) {
      this.srv.changePassword(value.oldPassword, value.newPassword).subscribe(
        () => this.ok = true,
        () => this.error = true
      );
    }
  }

  protected confirmValidator(control: FormControl) {
    const value = control.value;
    const toCheck = this.form.get('newPassword').value;
    return value === toCheck ? null : { notSame: true };
  }
}
