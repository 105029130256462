import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from './store/app.states';
import { LogOut, GetStatus } from './store/actions/auth.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'gasparini';
  isAuthenticated: boolean;
  anagrafiche = false;
  quotations = false;
  users = false;
  username: string;

  constructor(protected store: Store<AppState>) {
  }

  ngOnInit() {
    this.store.select(selectAuthState)
      .subscribe(userState => {
        this.isAuthenticated = userState.isAuthenticated;
        if (this.isAuthenticated) {
          this.username = userState.user.name;
        }
      });
    this.store.dispatch(new GetStatus());
  }

  logout() {
    this.store.dispatch(new LogOut());
  }
}
