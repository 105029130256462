import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-tandem-dialog',
  template: `<form [formGroup]="tandemForm" (submit)="confirm()" (keydown.enter)="$event.preventDefault()">
              <mat-form-field style="width: 100%">
                <mat-label>Number of machines</mat-label>
                <input matInput formControlName="qty" type="number" min="1">
              </mat-form-field>
              <div mat-dialog-actions>
                <button mat-raised-button color="primary" (click)="onNoClick()" type="button">Cancel</button>
                <button mat-raised-button color="warn" type="submit" cdkFocusInitial>
                    Save
                </button>
              </div>
            </form>`
})
export class TandemDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<TandemDialogComponent>,
    protected fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public qtyData: { qty: any }
  ) {
    this.tandemForm.patchValue({qty: qtyData.qty});
  }

  tandemForm = this.fb.group({
    qty: [0, Validators.required],
  });

  confirm() {
    if (this.tandemForm.invalid) { return; }

    this.dialogRef.close({ qty: this.tandemForm.value.qty });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
