import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentationLanguageService {

  constructor(protected http: HttpClient) { }

  list(): Observable<string[]> {
    return this.http.get<any>('/documentation-languages')
      .pipe(
        map(items => items.map(i => i.code))
      );
  }

  add(code: string): Observable<string> {
    return this.http.post<any>('/documentation-languages', {code})
      .pipe(
        map(data => data.code)
      );
  }

  remove(code: string): Observable<string> {
    return this.http.delete<any>(`/documentation-languages/${code}`)
    .pipe(
      map(data => data.code)
    );
  }
}
