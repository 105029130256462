import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class QuotationService {

  constructor(protected http: HttpClient) { }

  list(query?): Observable<any[]> {
    return this.http.get<any[]>('/quotations', { params: query });
  }

  size(): Observable<number> {
    return this.http.get<number>('/quotations/length');
  }

  get(id: string): Observable<any> {
    return this.http.get<any>(`/quotations/${id}`);
  }

  add(data: any): Observable<any> {
    return this.http.post<any>('/quotations', data);
  }

  update(id: string, data: any): Observable<any> {
    return this.http.put<any>(`/quotations/${id}`, data);
  }

  updateListino(idQuotation: string, idListino: string) {
    return this.http.put<any>(`/quotations/${idQuotation}/listino`, { listino: idListino });
  }

  updateVM(idQuotation: string, vmName: string) {
    return this.http.put<any>(`/quotations/${idQuotation}/vm`, { vm: vmName });
  }

  updateTandemVM(idQuotation: string, vmName: string) {
    return this.http.put<any>(`/quotations/${idQuotation}/tandemvm`, { vm: vmName });
  }

  delete(id: string) {
    return this.http.delete<any>(`/quotations/${id}`);
  }

  copy(id: string) {
    return this.http.post<any>(`/quotations/${id}/copy`, {});
  }

  getVersions(id: string) {
    return this.http.get<any>(`/quotations/${id}/versions`);
  }

  createVersion(id: string, data: any) {
    return this.http.post<any>(`/quotations/${id}/versions`, data);
  }

  promoteToDefault(id: string, versionId: string) {
    return this.http.post<any>(`/quotations/${id}/versions/${versionId}/default`, {});
  }

  getRevisions(id: string) {
    return this.http.get<any>(`/quotations/${id}/revisions`, {});
  }

  promoteRevision(id: string, revId: string, revision: string) {
    return this.http.post<any>(`/quotations/${id}/revisions/${revId}/promote`, {revision})
  }
}
