import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { isArray } from 'lodash';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(protected srv: AuthService, protected router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, snapshot: RouterStateSnapshot): Observable<boolean> {
    return this.srv.getStatus()
      .pipe(
        switchMap(user => {
          if (!user) {
            return of(false);
          }
          if (!route.data || !route.data.role) {
            return of(true);
          }
          const routeRoles = isArray(route.data.role) ? route.data.role : [route.data.role];
          const roles = user.roles as string[];
          let valid = user.roles.reduce((v, current) => {
            return v || routeRoles.includes(current);
          }, false);
          // valid =  roles.includes(route.data.role);
          if (!valid) {
            if (roles.includes('quotations') || roles.includes('am')) {
              this.router.navigateByUrl('/quotations');
            }

            if (roles.includes('datasets')) {
              this.router.navigateByUrl('/anagrafiche');
            }
            return of (false);

          }
          return of(true);
        }),
        catchError(() => {
          this.srv.removeToken();
          this.router.navigateByUrl(`/login?ref=${encodeURIComponent(snapshot.url)}`);
          return of(false);
        })
      );
  }
}
