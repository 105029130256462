import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { ListinoService } from 'src/app/_services/listino.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-listino-dialog',
  templateUrl: './vm-dialog.component.html',
  styleUrls: ['./vm-dialog.component.scss']
})
export class VMDialogComponent implements OnInit, OnDestroy {
  protected destroyed$ = new Subject();

  selectedVM = new FormControl('', Validators.required);

  vms$ = this.listinoSrv.getVMs(this.data.listino);
  filteredVms$ = combineLatest([
    this.selectedVM.valueChanges.pipe(startWith('')),
    this.vms$.pipe(startWith([] as string[]))
  ])
    .pipe(
      takeUntil(this.destroyed$),
      map(([filt, vms]) => {
        if (typeof (vms) === 'string') {
          return [vms];
        } else {
          if (!filt) {
            return vms;
          }
          const vmList: string[] = vms;
          return vmList.filter(opt => opt.toLowerCase().includes(filt));
        }
      })
    );

  constructor(
    public dialogRef: MatDialogRef<VMDialogComponent>,
    protected listinoSrv: ListinoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }



  changeVM() {
    if (this.selectedVM.invalid) { return; }
    this.dialogRef.close({ vm: this.selectedVM.value });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
