import { AuthService } from './auth.service';
import { GetStatus } from './../store/actions/auth.actions';
import { AppState, selectAuthState } from './../store/app.states';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap, take, filter, catchError } from 'rxjs/operators';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(protected srv: AuthService, protected router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Observable<boolean> {
    return of(!this.srv.getToken());
  }
}
