// tslint:disable: variable-name
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { intersection } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Section, SectionItem } from '../machineopt-increases/section-base';

export interface InstallationItem extends SectionItem {
  days: number;
  tech: number;
  discount1?: number;
  name?: string;
  hasDaysDisabled: boolean;
  hasTechDisabled: boolean;

  // travelling
  travKm?: number;
  travN?: number;
}

export class InstallationSectionHelper extends Section<InstallationItem> {
  public hasOnlyOneDiscount = true;
  staticItems: Partial<InstallationItem>[] =
    [
      {
        item: 'StartUp & Training',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'set',
        hasDaysDisabled: false,
        hasTechDisabled: false
      },
      {
        item: 'Number of engineers from local partner',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'nelp',
        hasDaysDisabled: true,
        hasTechDisabled: false
      },
      {
        item: 'Traveling',
        days: 1,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'trav',
        hasDaysDisabled: true,
        hasTechDisabled: true,
        travKm: 0,
        travN: 0
      },
      {
        item: 'Traveling Days',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'travdays',
        hasDaysDisabled: false,
        hasTechDisabled: true
      },
      {
        item: 'Board and lodging',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 20,
        listPrice: 0,
        name: 'bal',
        hasDaysDisabled: false,
        hasTechDisabled: false
      },
      {
        item: 'Daily Allowance',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 20,
        listPrice: 0,
        name: 'da',
        hasDaysDisabled: false,
        hasTechDisabled: true
      },
      {
        item: 'Car Rental (80,00 € per day)',
        days: 1,
        price: 0,
        tech: 1,
        unitPrice: 0,
        listPrice: 0,
        name: 'cr',
        hasDaysDisabled: true,
        hasTechDisabled: true
      },
      {
        item: 'Transfer (100,00 € each trip)',
        days: 1,
        price: 0,
        tech: 1,
        unitPrice: 0,
        listPrice: 0,
        name: 'trans',
        hasDaysDisabled: true,
        hasTechDisabled: true
      },
      {
        item: 'Working hours weekend',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'whw',
        hasDaysDisabled: false,
        hasTechDisabled: false
      }
    ];
  vmSelected$ = new BehaviorSubject('');
  vpsSelected$ = new BehaviorSubject([]);
  installationsSpecs$ = new BehaviorSubject([]);
  installationManPricesIt$ = new BehaviorSubject([]);
  installationManPricesFc$ = new BehaviorSubject([]);
  installationParameters$ = new BehaviorSubject<any>({});

  suggestedStartTrainDays$ = new BehaviorSubject(0);
  suggestedStartTrainTech$ = new BehaviorSubject(0);
  showWarning = false;

  suggestedWorkHoursDays$ = new BehaviorSubject(0);
  suggestedWorkHoursTech$ = new BehaviorSubject(0);

  footerNote$ = combineLatest(
    this.vmSelected$,
  ).pipe(
    switchMap((vmName) => {
      const vmInfo = this.installationsSpecs$.value.find(vms => vms.vm === vmName[0]);
      if (vmInfo) {
        return of(`${vmName[0]}: ${vmInfo.note !== '' ? vmInfo.note : 'no notes'}`);
      } else {
        return of('');
      }
    }),
  );

  hasDiscount$ = this.items$
    .pipe(
      map(items => {
        for (const item of items) {
          if (this.hasCustomDiscount(item)) {
            return true;
          }
        }
        return false;
      }),
    );

  constructor(title: string, public name: string) {
    super(title, name);
    this.registerRecalculateItems();
    this.hasDiscount$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => { this.updateColumns$.next(); });
  }

  registerRecalculateItems() {
    this.form.get('discount1').valueChanges.pipe(startWith(0))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.recalculateItems$);

    combineLatest(
      this.form.get('machineDestination').valueChanges.pipe(startWith('it')),
      this.vpsSelected$.pipe(startWith([])),
      this.form.get('bopr').valueChanges.pipe(startWith(false)),
      this.installationsSpecs$
    )
      .pipe(
        filter(() => !!this._items.length && !!this.installationsSpecs$.value.length),
        takeUntil(this.destroyed$)
      )
      .subscribe(([md, vps, bopr, inst]) => {
        this.resetDaysTech();
        this.updateDaysTechAndUp();
        if (vps.length !== 0) {
          this.addTandem(vps);
          this.addProperties(vps);
        }
        this.updateBendingOfPieces(this.vmSelected$.value, bopr);
        this.recalculateItems$.next();
      });

    // verifica se i days/tech di startup e training sono diversi da quelli consigliati, se si mostra warning
    combineLatest(
      this.suggestedStartTrainDays$.pipe(startWith(0)),
      this.suggestedStartTrainTech$.pipe(startWith(0)),
      (this.form.get('items') as FormArray).valueChanges.pipe(startWith([])),
    ).pipe(
      takeUntil(this.destroyed$),
      filter(([_, __, form]) => !!form[0]),
      map(([days, tech, formStartTrain]) => [days, tech, formStartTrain[0]])
    )
      .subscribe(([days, tech, formStartTrain]) => {
        if (formStartTrain.days !== days || formStartTrain.tech !== tech) {
          this.showWarning = true;
        } else {
          this.showWarning = false;
        }
      });
  }

  protected itemToFormGroup(item: InstallationItem): FormGroup {

    const fg = new FormGroup({
      item: new FormControl(item.item),
      days: new FormControl(item.days),
      tech: new FormControl(item.tech),
      unitPrice: new FormControl(item.unitPrice),
      listPrice: new FormControl(item.listPrice),
      price: new FormControl(item.price),
      name: new FormControl(item.name),
      hasDaysDisabled: new FormControl(item.hasDaysDisabled),
      hasTechDisabled: new FormControl(item.hasTechDisabled)
    });

    if (item.name === 'trav') {
      fg.removeControl('days');
      fg.removeControl('tech');
      fg.addControl('travKm', new FormControl(item.travKm));
      fg.addControl('travN', new FormControl(item.travN));
    }

    if (item.discount1 !== undefined) {
      fg.addControl('discount1', this.createDiscountForm(item.discount1));
    }

    return fg;
  }

  protected createForm() {
    const basicForm = new FormGroup({
      name: new FormControl(),
      machineDestination: new FormControl('it'),
      items: new FormArray([]),
      price: new FormControl(0),
      discount1: new FormControl(0),
      listPrice: new FormControl(0),
      bopr: new FormControl(false)
    });
    return basicForm;
  }

  changeMachineDestination() {
    const md = this.form.get('machineDestination');
    if (md.value === 'it') {
      md.patchValue('fc');
    } else {
      md.patchValue('it');
    }
  }

  setBendingButton() {
    const bopr = this.form.get('bopr');
    if (bopr.value) {
      bopr.patchValue(false);
    } else {
      bopr.patchValue(true);
    }
  }

  protected calculateItem(item: InstallationItem, index): InstallationItem {
    // set
    // nelp
    // trav
    // travdays
    // bal
    // da
    // cr
    // trans
    // whw

    if (item.name !== 'set') {
      if (item.name === 'whw') {
        this.setWorkHoursWeekendDaysTech(item);
      }
      if (item.name === 'nelp' ||
        // item.name === 'trav' ||
        item.name === 'travdays' ||
        item.name === 'da'
        // || item.name === 'cr'
      ) {
        item.tech = this.getItemControl(0, 'tech').value;
      }
    }

    const secDiscount = this.form.get('discount1').value;
    const hasDiscount = this.hasCustomDiscount(item);
    const discount1 = hasDiscount ? item.discount1 : secDiscount;

    let listPrice = 0;

    if (item.name === 'trav') {
      const travKm = this.getItemControl(index, 'travKm').value;
      const travQty = this.getItemControl(index, 'travN').value;
      item.unitPrice = travKm * travQty;
      this.getItemControl(index, 'unitPrice').patchValue(item.unitPrice, { emitEvent: false });

      const installationParameters = this.installationParameters$.value[0];
      if (!!installationParameters) {
        listPrice = item.unitPrice * installationParameters.travelCoefficent;
      }
    } else {
      listPrice = item.days * item.tech * item.unitPrice;
    }
    item.listPrice = listPrice;
    item.price = listPrice * (1 - discount1 / 100);

    this.getItemControl(index, 'listPrice').patchValue(item.listPrice, { emitEvent: false });
    this.getItemControl(index, 'price').patchValue(item.price, { emitEvent: false });
    return item;
  }

  hasCustomDiscount(item: InstallationItem) {
    return item.discount1 !== undefined;
  }

  protected calcColumns(): Observable<string[]> {
    for (const item of this._items) {
      if (this.hasCustomDiscount(item)) {
        return of(
          [
            'autext_description',
            'inst_days',
            'inst_tech',
            'inst_unitPrice',
            'listPrice',
            'singlecs_discount',
            'price',
          ]
        );
      }
    }
    return of(
      [
        'autext_description',
        'inst_days',
        'inst_tech',
        'inst_unitPrice',
        'listPrice',
        'price',
      ]
    );
  }

  protected createDiscountForm(discount) {
    return new FormControl(discount, [Validators.min(0), Validators.max(100)]);
  }

  setCustomDiscount(item: InstallationItem) {
    if (!this.hasCustomDiscount(item)) {
      const index = this._items.indexOf(item);
      if (index !== -1) {
        const formArr = this.form.get('items') as FormArray;
        const itemGr = formArr.at(index) as FormGroup;
        itemGr.addControl('discount1', this.createDiscountForm(0));
      }
    }
  }

  removeCustomDiscount(item: InstallationItem) {
    if (this.hasCustomDiscount(item)) {
      const index = this._items.indexOf(item);
      if (index !== -1) {
        const formArr = this.form.get('items') as FormArray;
        const itemGr = formArr.at(index) as FormGroup;
        itemGr.removeControl('discount1');
      }
    }
  }

  setUnitPriceStep(elementName) {
    let step = 1;
    if (elementName === 'cr') {
      step = 80;
    }
    if (elementName === 'trans') {
      step = 100;
    }
    return step;
  }

  resetFields() {
    this.form.reset();
  }

  footerRow(): string[] {
    return ['autext_description'];
  }

  resetDaysTech() {

    const formArr = this.form.get('items') as FormArray;
    const item = formArr.at(0);
    if (item) {
      this.suggestedStartTrainDays$.next(0);
      this.suggestedStartTrainTech$.next(0);
    }

  }

  /**
   * aggiorna days e tech di startup&training
   * e unitPrice di startup&training - traveldays - workhoursweekend
   * based on machine destination
   */
  updateDaysTechAndUp() {

    const machineDest = this.form.get('machineDestination').value;
    const vmName = this.vmSelected$.value;
    const installationManPricesIt = this.installationManPricesIt$.value;
    const installationManPricesFc = this.installationManPricesFc$.value;

    if (machineDest === 'it' && installationManPricesIt.length > 0) {

      const itemSetIdx = this._items.findIndex((it) => it.name === 'set');
      const itemTravDaysIdx = this._items.findIndex((it) => it.name === 'travdays');
      const itemWhwIdx = this._items.findIndex((it) => it.name === 'whw');

      this.getItemControl(itemSetIdx, 'unitPrice')
        .patchValue(installationManPricesIt[0].techDayUp,
          { emitEvent: true, onlySelf: false }); // imposto unit price per StartUp & Training (set)

      this.getItemControl(itemTravDaysIdx, 'unitPrice')
        .patchValue(installationManPricesIt[0].travelDayUp,
          { emitEvent: true, onlySelf: false }); // imposto unit price per Travel Days (travdays)

      this.getItemControl(itemWhwIdx, 'unitPrice')
        .patchValue(installationManPricesIt[0].workHoursWeUp,
          { emitEvent: true, onlySelf: false }); // imposto unit price per Work hours (Whw)

    }

    if (machineDest === 'fc' && installationManPricesFc.length > 0) {
      const itemSetIdx = this._items.findIndex((it) => it.name === 'set');
      const itemTravDaysIdx = this._items.findIndex((it) => it.name === 'travdays');
      const itemUpIdx = this._items.findIndex((it) => it.name === 'whw');

      this.getItemControl(itemSetIdx, 'unitPrice').patchValue(installationManPricesFc[0].techDayUp,
        { emitEvent: true, onlySelf: false }); // imposto unit price per StartUp & Training (set)
      this.getItemControl(itemTravDaysIdx, 'unitPrice').patchValue(installationManPricesFc[0].travelDayUp,
        { emitEvent: true, onlySelf: false }); // imposto unit price per Travel Days (travdays)
      this.getItemControl(itemUpIdx, 'unitPrice').patchValue(installationManPricesFc[0].workHoursWeUp,
        { emitEvent: true, onlySelf: false }); // imposto unit price per Work hours (Whw)

      /*
      // MOTIVO colonne rimosse da excel --> "differenza tra italia e estero"
       if (vmName) {
        const vmSpecs = this.installationsSpecs$.value.find((it) => it.vm === vmName);
        const startTrain_engineersInst = vmSpecs.properties.filter((it) => it.name.includes('setfc'));

        this.suggestedStartTrainDays$.next(startTrain_engineersInst.find((it => it.name === 'setfc_d')).value);
        this.suggestedStartTrainTech$.next(startTrain_engineersInst.find((it => it.name === 'setfc_e')).value);
      } */

    }

    if (vmName) {
      const vmSpecs = this.installationsSpecs$.value.find((it) => it.vm === vmName);
      const startTrain_engineersInst = vmSpecs.properties.filter((it) => it.name.includes('seti'));

      this.suggestedStartTrainDays$.next(startTrain_engineersInst.find((it => it.name === 'seti_d')).value);
      this.suggestedStartTrainTech$.next(startTrain_engineersInst.find((it => it.name === 'seti_e')).value);

    }
    this.form.get('items').updateValueAndValidity();
  }

  setWorkHoursWeekendDaysTech(item: InstallationItem) {
    const workHours = this.getItemControl(0, 'days').value > 5 ? this.getItemControl(0, 'days').value - 5 : 0;
    const tech = this.getItemControl(0, 'tech').value;

    this.suggestedWorkHoursDays$.next(workHours)
    this.suggestedWorkHoursTech$.next(tech)
  }

  /**
   * Aggiungo tandem2machine ai tech e day di startup&training
   * se la qty della vm selezionata è >1
   */
  addTandem(vpsSelected: any[]) {
    this.updateDaysTechAndUp();
    const currItemDays = this.suggestedStartTrainDays$.value;
    const currItemTech = this.suggestedStartTrainTech$.value;

    if (vpsSelected.length > 0) {
      const vmSelected = vpsSelected.find(vp => vp.kind === 'vm');
      if (!vmSelected || vmSelected.qty < 1) { return; }
      const vmSpecs = this.installationsSpecs$.value.find((it) => it.vm === vmSelected.item);
      const tandem2days = vmSpecs.properties.find((prop) => prop.name === 't2_d');
      const tandem2tech = vmSpecs.properties.find((prop) => prop.name === 't2_e');

      if (vmSelected.qty > 1) {
        this.suggestedStartTrainDays$.next(currItemDays + tandem2days.value * (vmSelected.qty - 1));
        this.suggestedStartTrainTech$.next(currItemTech + (tandem2tech.value * vmSelected.qty - 1));
      }
    }
  }

  /**
   * aggiungo i days a startup&training se Bending of pieces
   * è cliccato
   */
  updateBendingOfPieces(vmName: string, currBending) {
    if (!vmName || this.installationsSpecs$.value.length <= 0) {
      return;
    }
    const vmSpecs = this.installationsSpecs$.value.find((it) => it.vm === vmName);
    const boprVm = vmSpecs.properties.find((prop) => prop.name === 'boprbc_d');
    const currItemDays = this.suggestedStartTrainDays$.value;
    if (currBending) {
      this.suggestedStartTrainDays$.next(currItemDays + boprVm.value);
    }
  }

  /**
   * Aggiunge il valore richiesto se almeno una delle vps selezionate
   * corrisponde a quella di sf_d, gpsa_d, gpsb_d, ...
   */
  addProperties(vpsSel) {
    const vpsSelected = [...vpsSel];
    if (vpsSelected.length === 0) { return; }

    // rimuovo vm dall'array delle vps
    const vmIndex = vpsSelected.findIndex(vps => vps.kind === 'vm');
    if (vmIndex === -1) { return; }
    const vmSelected = vpsSelected.splice(vmIndex, 1);

    if (vmSelected.length === 0) { return; }
    const vmSpecs = this.installationsSpecs$.value.find((it) => it.vm === vmSelected[0].item);

    // recupero le proprietà di sf_d, gpsa_d, gpsb_d
    const sf = vmSpecs.properties.find((prop) => prop.name === 'sf_d');
    const gpsa = vmSpecs.properties.find((prop) => prop.name === 'gpsa_d');
    const gpsb = vmSpecs.properties.find((prop) => prop.name === 'gpsb_d');
    const da69a_d = vmSpecs.properties.find((prop) => prop.name === 'da69a_d');
    const cua_d = vmSpecs.properties.find((prop) => prop.name === 'cua_d');
    const cua_e = vmSpecs.properties.find((prop) => prop.name === 'cua_e');
    const da66a = vmSpecs.properties.find((prop) => prop.name === 'da66a_d');

    const itemDay$ = this.suggestedStartTrainDays$;
    const itemTech$ = this.suggestedStartTrainTech$;
    let currItemDays: number;
    let currItemTech: number;

    vpsSelected.forEach(vp => {
      const vpsInSf = sf ? sf.vps.filter(sfVp => sfVp === vp.item.toLowerCase()) : [];
      const vpsInGpsa = gpsa ? gpsa.vps.filter(gpsaVp => gpsaVp === vp.item.toLowerCase()) : [];
      const vpsInGpsb = gpsb ? gpsb.vps.filter(gpsbVp => gpsbVp === vp.item.toLowerCase()) : [];
      const vpsInda69a_d = da69a_d ? da69a_d.vps.filter(da69aVp => da69aVp === vp.item.toLowerCase()) : [];
      const vpsInCua_d = cua_d ? cua_d.vps.filter(cuadVp => cuadVp === vp.item.toLowerCase()) : [];
      const vpsInCua_e = cua_e ? cua_e.vps.filter(cuaeVp => cuaeVp === vp.item.toLowerCase()) : [];
      const vpsInDa66a = da66a ? da66a.vps.filter(da66VP => da66VP === vp.item.toLowerCase()) : [];
      // se le vp interessante corrispondono a quelle sopra (sf_d, gpsa_d, gpsb_d)
      // aggiungo i giorni su startup & training di installation
      if (vpsInSf.length > 0) {
        currItemDays = itemDay$.value;
        itemDay$.next(currItemDays + sf.value);
      }

      if (vpsInGpsa.length > 0) {
        currItemDays = itemDay$.value;
        itemDay$.next(currItemDays + gpsa.value);
      }

      if (vpsInGpsb.length > 0) {
        currItemDays = itemDay$.value;
        itemDay$.next(currItemDays + gpsb.value);
      }

      if (vpsInda69a_d.length > 0) {
        currItemDays = itemDay$.value;
        itemDay$.next(currItemDays + da69a_d.value);
      }

      if (vpsInCua_d.length > 0) {
        currItemDays = itemDay$.value;
        itemDay$.next(currItemDays + cua_d.value);
      }

      if (vpsInCua_e.length > 0) {
        currItemTech = itemTech$.value;
        itemTech$.next(currItemTech + cua_e.value);
      }

      if (vpsInDa66a.length > 0) {
        currItemDays = itemDay$.value;
        itemDay$.next(currItemDays + da66a.value);
      }

    });

    // recupero le proprietà di da58a_d, da58b_d, da66a_d
    // const da58a = vmSpecs.properties.find((prop) => prop.name === 'da58a_d');
    const da58b = vmSpecs.properties.find((prop) => prop.name === 'da58b_d');


    const vpsSelectedNames = vpsSelected.map(vp => vp.item.toLowerCase());

    if (intersection(da58b.vps, vpsSelectedNames).length === da58b.vps.length) {

      currItemDays = itemDay$.value;
      itemDay$.next(currItemDays + da58b.value);
    }


    /* MOTIVO: colonna rimosse excel --> non c'è + differenza tra design easy e next
    const da66b = vmSpecs.properties.find((prop) => prop.name === 'da66b_d');
    if (intersection(da66b.vps, vpsSelectedNames).length === da66b.vps.length) {
    currItemDays = itemDay$.value;
    itemDay$.next(currItemDays + da66b.value);
  }
    */


  }

}
