import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { State } from './../../store/reducers/auth.reducers';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, selectAuthState } from 'src/app/store/app.states';
import { LogIn } from 'src/app/store/actions/auth.actions';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map, filter } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });
  getState: Observable<State>;
  errorMessage: string | null;

  protected redirect: string;
  protected destroy$ = new Subject();

  constructor(protected fb: FormBuilder,
              protected store: Store<State>,
              activatedRoute: ActivatedRoute) {
    this.getState = this.store.select(selectAuthState);
    activatedRoute.queryParams
      .pipe(
        takeUntil(this.destroy$),
        map(params => params.ref),
        filter(ref => !! ref)
      )
      .subscribe(ref => this.redirect = ref);
  }

  ngOnInit() {
    this.getState
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => {
        this.errorMessage = state.errorMessage;
      });
  }

  login() {
    if (this.loginForm.valid) {
      const { username, password } = this.loginForm.value;
      const payload = {
        username,
        password,
        redirect: this.redirect
      };
      this.store.dispatch(new LogIn(payload));
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
