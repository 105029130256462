import { Component } from '@angular/core';

@Component({
  selector: 'app-saving-dialog',
  template: `
            <h2 mat-dialog-title>Generating Documents</h2>
            <div fxLayout="row" fxLayoutAlign="center">
              <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
            </div>
            `,
  styleUrls: []
})
export class SavingDialogComponent { }
