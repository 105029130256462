import { VpSectionHelper } from './vp-section';
import { of, Observable } from 'rxjs';

export class AutomationExternalSectionHelper extends VpSectionHelper {
    constructor(title: string, name: string, freeAdd = false) {
        super(title, name, freeAdd);
        this._showAddNew = true;
    }

    // non mi serve il toggle dell'add new perché gli input si vedono di default
    toggleAddNew() { }

    resetFields() {
        this.customItemForm.reset();
    }

    protected calcColumns(): Observable<string[]> {
        for (const item of this._items) {
            if (this.hasCustomDiscount(item)) {
                return of(['autext_description', 'discount', 'unitPrice', 'qty', 'listPrice', 'price', 'actions']);
            }
        }
        return of(['autext_description', 'unitPrice', 'qty', 'listPrice', 'price', 'actions']);
    }
    footerRow() {
        return ['autext_description'];
    }
}
