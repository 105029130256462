import { AppState } from './../store/app.states';
import { Store } from '@ngrx/store';
import { Directive, TemplateRef, ViewContainerRef, OnInit, Input } from '@angular/core';
import { isArray } from 'lodash';
import { AclService, AclState } from './acl.service';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[hasRole]'
})
export class HasRoleDirective implements OnInit {
  private fetched = false;
  private permissions = [];
  private requestedPermission: string[] = null;
  private aclState: AclState = null;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    protected store: Store<AppState>,
    private acl: AclService
  ) { }

  ngOnInit() {
    this.acl.aclState
      .subscribe(state => {
        this.aclState = state;
        this.updateView();
      });
  }

  @Input()
  set hasRole(val) {
    if (val) {
      let requested = [];
      if (!isArray(val)) {
        requested = [val];
      } else {
        requested = val;
      }
      this.requestedPermission = requested.map(p => p.toLowerCase());
    } else {
      this.requestedPermission = null;
      this.updateView();
    }
  }

  private updateView() {
    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    if (!this.aclState) {
      return false;
    }
    return this.requestedPermission.reduce((allowed, curr) => {
      return allowed || this.aclState.hasPermission(curr);
    }, false);
  }
}
