import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-restore-revision-dialog',
  templateUrl: './restore-revision-dialog.component.html',
  styleUrls: ['./restore-revision-dialog.component.scss']
})
export class RestoreRevisionDialogComponent implements OnInit {
  prevRevision: number;

  revisionForm = new FormGroup({
    revision: new FormControl(false, Validators.required)
  });

  constructor(
    public dialogRef: MatDialogRef<RestoreRevisionDialogComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  save() {
    const revision = this.revisionForm.value.revision;
    this.dialogRef.close({
      revision
    });
  }

  closeWithoutSave() {
    this.dialogRef.close(null);
  }
}
