import { AuthGuard } from './../shared/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserListComponent } from './list/list.component';
import { UserEditComponent } from './edit/edit.component';
import { MeComponent } from './me/me.component';
import { AddUserComponent } from './add/add.component';

const routes: Routes = [

  {
    path: 'users/me',
    component: MeComponent
  },
  {
    path: 'users',
    children: [
      {
        path: '',
        component: UserListComponent
      },
      {
        path: 'add',
        component: AddUserComponent
      },
      {
        path: ':id',
        component: UserEditComponent
      }
    ],
    data: {
      role: 'users'
    },
    canActivate: [AuthGuard]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
