import { VPEditDialogComponent } from './vp-edit-dialog/vp-edit-dialog.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from "@angular/core";
import { VpDetailService } from "src/app/_services/vp-detail.service";
import { assign } from 'lodash';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-vp-detail-list',
  templateUrl: './vp-detail.component.html'
})
export class VpDetailListComponent implements OnInit {
  list: any[] = [];
  list$ = new Subject<any[]>();

  constructor(protected srv: VpDetailService,
              protected dialog: MatDialog) {}

  ngOnInit(): void {
    this.srv.list()
      .subscribe(data => {
        this.list = data;
        this.refreshList();
      });
  }

  protected refreshList() {
    this.list$.next(this.list);
  }

  protected updateItem(original, updated) {
    assign(original, updated);
    this.refreshList();
  }

  edit(vp: any) {
    this.dialog.open(VPEditDialogComponent, {data: vp})
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.srv.update(vp._id, data.name, data.languages, data.picture)
            .subscribe(updated => {
              this.updateItem(vp, updated);
            });
        }
      });
  }

  add() {
    this.dialog.open(VPEditDialogComponent)
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.srv.create(data.name, data.languages, data.picture)
            .subscribe(created => {
              this.list.splice(0, 0, created);
              this.refreshList();
            });
        }
      });
  }

  getPictureUrl(item: any) {
    return `/api/vp-details/${item._id}/picture?hash=${(new Date(item.updatedAt)).getTime()}`;
  }
}
