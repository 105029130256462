import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.scss']
})
export class StatusSelectComponent implements OnInit {
  constructor() { }

  @Input() quotation: any;
  @Output() statusChange = new EventEmitter<any>();
  statusTypes = ['in progress', 'completed', 'approved'];

  ngOnInit() {
  }
  onStatusChange(status: string) {
    if (status) {
      this.statusChange.emit(status);
      this.quotation.status = status;
    }
  }
}
