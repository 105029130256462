import { cloneDeep } from 'lodash';
import { Observable, Subject, combineLatest } from 'rxjs';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';

export interface SectionItem {
    item: string;
    description: string;
    qty: number;
    kind: string;
    unitPrice: number;
    listPrice: number;
    toCheck: boolean;
    toBeDefined: boolean;
    standard: boolean;
    price: number;
    note: string;
}

export abstract class Section<T extends SectionItem> {
    get dropConnectedTo(): string[] {
      return [];
    }

    title: string;
    protected updateColumns$ = new Subject<void>();
    columns$ = this.updateColumns$
        .pipe(
            startWith(() => { }),
            switchMap(() => this.calcColumns())
        );
    form = this.createForm();
    protected recalculateItems$ = new Subject<void>();

    items$ = combineLatest(
        this.form.get('items').valueChanges.pipe(startWith([])) as Observable<T[]>,
        this.recalculateItems$.pipe(startWith(() => { }))
    ).pipe(
        map(([items, _]) => {
          return items.map((item, i) => this.calculateItem(item, i));
        })
    );
    listPrice$ = this.items$.pipe(
        map(items => items.map(item => item.listPrice)),
        map(prices => {
            return prices.reduce((prev, current) => {
                return prev + (current || 0);
            }, 0);
        }),
        startWith(0)
    );

    price$ = this.items$.pipe(
        map(items => items.map(item => item.price)),
        map(prices => {
            return prices.reduce((prev, current) => {
                return prev + current;
            }, 0);
        }),
        startWith(0)
    );

    get items(): T[] {
      return cloneDeep(this._items);
    }

    // tslint:disable-next-line: variable-name
    protected _items: T[] = [];

    protected destroyed$ = new Subject<void>();
    /**
     *
     */
    constructor(title: string, public name: string) {
        this.title = title;
        this.form.patchValue({ name });

        this.items$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(items => {
                this._items = items;
            });
        this.price$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(price => {
                this.form.get('price').patchValue(price, { onlySelf: true });
            });

        this.listPrice$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(price => {
                this.form.get('listPrice').patchValue(price, { onlySelf: true });
            });
    }

    getItemControl(index: number, controlName: string) {
        const formArr = this.form.get('items') as FormArray;
        return formArr.at(index).get(controlName);
    }

    addItem(item: Partial<T>) {
        this._addItem(item);
        this.recalculateItems$.next();
    }

    addItemAt(item: Partial<T>, index: number) {
      const formItems = this.form.get('items') as FormArray;
      const newControl = this.itemToFormGroup(item);
      formItems.insert(index, newControl);
      this.recalculateItems$.next();
    }

    addItems(items: Partial<T>[]) {
        items.forEach(item => {
            this._addItem(item);
        });
        this.recalculateItems$.next();
    }

    multiplyQty(oldMachineNumber: number, amount: number) {
      const formArr = this.form.get('items') as FormArray;
      // tslint:disable-next-line:no-unused-expression
      for (let i = 0; i < formArr.length; i++) {
        const control = formArr.at(i).get('qty');
        const oldValue = control.value;
        const newValue = Math.round(oldValue / oldMachineNumber * amount);
        control.patchValue(newValue);
      }
    }

    removeItem(item: T) {
        const index = this._items.indexOf(item);
        if (index !== -1) {
            const formItems = this.form.get('items') as FormArray;
            formItems.removeAt(index);
        }
    }

    moveItem(prevIndex: number, newIndex: number) {
      const formItems = this.form.get('items') as FormArray;
      const item = formItems.at(prevIndex);
      formItems.removeAt(prevIndex);
      formItems.insert(newIndex, item);
    }
    /**
     * Pulisce il form della sezione
     */
    clear() {
        const formArray = this.form.get('items') as FormArray;
        formArray.clear();
    }

    destroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    protected _addItem(item: Partial<T>) {
        const formItems = this.form.get('items') as FormArray;
        const newControl = this.itemToFormGroup(item);
        formItems.push(newControl);
    }

    protected createForm() {
        return new FormGroup({
            name: new FormControl(),
            items: new FormArray([]),
            price: new FormControl(0),
            listPrice: new FormControl(0),
        });
    }

    protected itemToFormGroup(item: Partial<T>): FormGroup {
        return new FormGroup({
            item: new FormControl(item.item),
            description: new FormControl(item.description),
            qty: new FormControl(item.qty),
            kind: new FormControl(item.kind),
            note: new FormControl(item.note),
            unitPrice: new FormControl(item.unitPrice),
            listPrice: new FormControl(item.listPrice),
            price: new FormControl(item.price),
            standard: new FormControl(item.standard),
            toCheck: new FormControl(item.toCheck),
            toBeDefined: new FormControl(item.toBeDefined)
        });
    }

    protected calculateItem(item: T, index: number): T {
        return item;
    }

    protected abstract calcColumns(): Observable<string[]>;
    protected abstract footerRow(): string[];

}

export abstract class CustomPropertiesSection<T extends SectionItem> extends Section<T> {
    showAddNew: boolean;
    customItemForm: FormGroup;
    addControl: FormControl;
    addOptions$: Observable<T[]>;

    updateOptionsSource(items: T[]): void { }
    addNewItem(): void { }
    toggleAddNew(): void { }
    hasCustomDiscount(item: T): boolean {
        return false;
    }
}
