import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root'
})
export class InstallationService {

  constructor(protected http: HttpClient) { }

  import(formData): Observable<any> {
    return this.http.post('/installations/import', formData);
  }

  list(): Observable<any> {
    return this.http.get<any>('/installations');
  }

  addManPrices_it(prices: any): Observable<any> {
    return this.http.post('/installation-man-it', prices);
  }

  addManPrices_fk(prices: any): Observable<any> {
    return this.http.post('/installation-man-fc', prices);
  }

  getManPrices_it(): Observable<any> {
    return this.http.get('/installation-man-it');
  }

  getManPrices_fc(): Observable<any> {
    return this.http.get('/installation-man-fc');
  }

  addParameters(typeName: string, value: number): Observable<any> {
    return this.http.post(`/parameters/${typeName}`, { value });
  }

  getParameters(): Observable<any[]> {
    return this.http.get<any[]>('/parameters')
      .pipe(
        map(items => items.map(item => ({ [item.typeName]: item.value })))
      );
  }
}
