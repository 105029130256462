import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from './user.model';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';


@Injectable()
export class AuthService {

  constructor(private http: HttpClient,
            protected cookieSrv: CookieService) { }

  getToken(): string {
    return localStorage.getItem('token');
  }

  removeToken() {
    this.cookieSrv.delete('auth');
    return localStorage.removeItem('token');
  }

  logIn(username: string, password: string): Observable<any> {
    const url = `/auth/local`;
    return this.http.post<User>(url, { username, password });
  }

  getStatus(): Observable<any> {
    const url = `/users/me`;
    return this.http.get<User>(url);
  }
}
