import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from './../users.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-user-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class UserEditComponent implements OnInit {
  roles: string[] = [];
  routeId: string;
  userId: string;
  user$ = this.route.params
    .pipe(
      map(data => data.id),
      tap(id => this.routeId = id),
      switchMap(id => this.srv.get(id)),
      tap(user => this.userId = user._id),
      tap(user => this.roles = user.roles || [])
    );
  form = this.fb.group({
    username: ['', Validators.required],
    name: ['', Validators.required]
  });

  constructor(
    protected route: ActivatedRoute,
    protected srv: UserService,
    protected fb: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.user$.subscribe(user => this.form.patchValue(user));
  }

  save() {
    const { valid, value } = this.form;
    if (valid) {
      // TODO gestire aggiornamento dell'utente loggato
      this.srv.update(this.userId, value)
        .subscribe(data => {
          this.form.patchValue(data);
          this.snackBar.open('Saved!', null, { duration: 3000 });
        });
    }
  }

  resetPassword() {
    this.srv.resetPassword(this.userId)
      .subscribe(newPassword => {
        const data = { newPassword };
        this.dialog.open(ResetPasswordComponent, { disableClose: true, data });
      });
  }

  hasRole(role: string) {
    return this.roles.indexOf(role) !== -1;
  }

  toggleRole(event, role: string) {
    if (event.checked) {
      this.srv.addRole(this.userId, role)
        .subscribe(_ => this.snackBar.open('Updated!', null, { duration: 3000 }));
      this.roles.push(role);
    } else {
      this.srv.removeRole(this.userId, role)
        .subscribe(_ => this.snackBar.open('Updated!', null, { duration: 3000 }));
      if (this.hasRole(role)) {
        const index = this.roles.indexOf(role);
        this.roles.splice(index, 1);
      }
    }
  }

}

@Component({
  selector: 'app-reset-password-dialog',
  template: `<div style="text-align: center;">
              <div>
                <h3>Save and share this password with the user, it will not be shown again!</h3>
                <p>{{newPassword}}</p>
              </div>
              <div>
                <button mat-raised-button color="primary" mat-dialog-close>Close</button>
              </div>
            </div>`,
})
export class ResetPasswordComponent {
  newPassword: string;

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.newPassword = data.newPassword;
  }
}
