import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../users.service';
import { FormBuilder, Validators, FormControl } from '@angular/forms';

const PasswordValidation = [
  Validators.required,
  Validators.minLength(6),
  Validators.maxLength(24),
];

@Component({
  selector: 'app-add-user',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddUserComponent implements OnInit {
  ok = false;
  error = false;

  form = this.fb.group({
    username: ['', Validators.required],
    name: ['', Validators.required],
    password: ['', PasswordValidation],
    confirmPassword: ['', PasswordValidation]
  });

  constructor(protected router: Router,
    protected srv: UserService,
    protected fb: FormBuilder) { }

  ngOnInit() {
    this.form.get('confirmPassword').setValidators(PasswordValidation.concat([this.confirmValidator.bind(this)]));
  }

  save() {
    this.ok = false;
    this.error = false;
    const { valid, value } = this.form;
    if (valid) {
      this.srv.create(value).subscribe(
        (user) => this.router.navigate(['/users/', user._id]),
        () => this.error = true
      );
    }
  }

  protected confirmValidator(control: FormControl) {
    const value = control.value;
    const toCheck = this.form.get('password').value;
    return value === toCheck ? null : { notSame: true };
  }
}
