import { Subject, Observable, of, combineLatest, BehaviorSubject } from 'rxjs';
import { startWith, switchMap, map, tap, takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl, FormArray, Validators, AbstractControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { Section, SectionItem } from '../machineopt-increases/section-base';


export interface TransportItem extends SectionItem {
    margin?: number;
    inputDisabled?: boolean;
}



export class TransportHelper extends Section<TransportItem> {
    public hasOnlyOneMargin = true;

    placeDispatch = 'Istrana (TV) Italy';

    // tslint:disable-next-line:variable-name
    _selectItems = [
        [
            'FCA (ICC 2020)',
            'CPT (ICC 2020)',
            'DAT (ICC 2020)',
            'CIP (ICC 2020)',
            'FCA (ICC 2010)',
            'CPT (ICC 2010)',
            'DAT (ICC 2010)',
            'CIP (ICC 2010)'
        ],
        [
            'Nylon Wrap',
            'Wooden Crate'
        ]
    ];
    staticItems: Partial<TransportItem>[][] = [
        [
            {
                item: 'FCA (ICC 2020)',
                description: this.placeDispatch,
                unitPrice: 0,
                price: 0,
                inputDisabled: true,
                kind: 'incoterms'

            },
            {
                item: 'CPT (ICC 2020)',
                description: this.placeDispatch,
                unitPrice: 0,
                price: 0,
                inputDisabled: false,
                kind: 'incoterms'

            },
            {
                item: 'DAT (ICC 2020)',
                description: this.placeDispatch,
                unitPrice: 0,
                price: 0,
                inputDisabled: false,
                kind: 'incoterms'

            },
            {
                item: 'CIP (ICC 2020)',
                description: this.placeDispatch,
                unitPrice: 0,
                price: 0,
                inputDisabled: false,
                kind: 'incoterms'

            },
        ],
        [
            {
                item: 'Nylon Wrap',
                description: 'Packing',
                unitPrice: 0,
                price: 0,
                inputDisabled: true,
                kind: 'packing'
            },
            {
                item: 'Wooden Crate',
                description: 'Packing',
                unitPrice: 0,
                price: 0,
                inputDisabled: false,
                kind: 'packing'

            }
        ]
    ];

    itemToInitForm: Partial<TransportItem>[] = [
        {
            item: '',
            description: this.placeDispatch,
            unitPrice: 0,
            price: 0,
            inputDisabled: true,
            kind: 'incoterms'

        },
        {
            item: '',
            description: 'Packing',
            unitPrice: 0,
            price: 0,
            inputDisabled: false,
            kind: 'packing'
        }
    ];


    constructor(title: string, public name: string) {
        super(title, name);
        this.registerRecalculateItems();
        // this.hasMargin$.subscribe(() => { this.updateColumns$.next(); });

    }

    /**
     * You can pass the select group name (incoterms or packing)
     * @param formGroupName incoterms or packing
     */
    getFormGroup(formGroupName: string);

    /**
     * You can pass index
     * @param index position in formarray
     */
    // tslint:disable-next-line: unified-signatures
    getFormGroup(index: number);
    getFormGroup(identifier: any): AbstractControl {
        const fa = this.form.get('items') as FormArray;

        if (typeof identifier === 'number') {
            identifier = identifier;
            return fa.controls[identifier];
        }

        if (typeof identifier === 'string') {
            const position = identifier === 'incoterms' ? 0 : 1;
            return fa.controls[position];
        }
    }

    selectionChange(event, index) {
    }

    registerRecalculateItems() {

        this.form.get('margin').valueChanges.pipe(startWith(0))
            .pipe(takeUntil(this.destroyed$))
            .subscribe(this.recalculateItems$);
    }

    protected createMarginForm(margin) {
        return new FormControl(margin, [Validators.min(0), Validators.max(100)]);
    }


    protected itemToFormGroup(item: TransportItem): FormGroup {
        const fg = new FormGroup({
            item: new FormControl(item.item),
            description: new FormControl(item.description),
            unitPrice: new FormControl(item.unitPrice),
            price: new FormControl(item.price),
            kind: new FormControl(item.kind),
            inputDisabled: new FormControl(item.inputDisabled),
            listPrice: new FormControl(item.unitPrice)
        });

        fg.get('item').valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(val => {
                const priceControl = fg.get('unitPrice');
                if (val === 'FCA (ICC 2010)') {
                    priceControl.patchValue(0);
                    priceControl.disable();
                } else {
                    priceControl.enable();
                }
            });

        if (item.margin !== undefined) {
            fg.addControl('margin', this.createMarginForm(item.margin));
        }

        return fg;
    }

    protected _addItem(item: Partial<TransportItem>) {
        super._addItem(item);
        if (item.description === 'Packing') {
            const formItems = this.form.get('items') as FormArray;
            const lastItem = formItems.at(formItems.length - 1) as FormGroup;
            lastItem.get('description').disable();
        }
    }

    protected createForm() {

        const basicForm = new FormGroup({
            name: new FormControl(),
            items: new FormArray([]),
            price: new FormControl(0),
            margin: new FormControl(0),
            listPrice: new FormControl(0)

        });
        return basicForm;
    }


    protected calculateItem(item: TransportItem, index): TransportItem {
        const sectionMargin = this.form.get('margin').value;
        const margin = sectionMargin;
        if (item.item.includes('FCA')) {
            item.unitPrice = 0;
        }
        item.price = item.unitPrice * (1 + margin / 100);
        this.getItemControl(index, 'listPrice').patchValue(item.unitPrice, {emitEvent: false});
        this.getItemControl(index, 'price').patchValue(item.price, {emitEvent: false});
        return item;
    }

    protected calcColumns(): Observable<string[]> {
        return of(
            [
                'transp_item',
                'transp_description',
                'transp_unitPrice',
                'price',
            ]
        );
    }
    footerRow(): string[] {
        return [];
    }
}
