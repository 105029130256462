// tslint:disable: variable-name
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Section, SectionItem } from '../machineopt-increases/section-base';

export interface AdditionalWarrantyItem extends SectionItem {
  rate: number;
  months: number;
  discount1?: number;
}

export class AdditionalWarrantyHelper extends Section<AdditionalWarrantyItem> {
  public hasOnlyOneDiscount = true;
  staticItems: Partial<AdditionalWarrantyItem>[] =
    [
      {
        item: 'Spare parts',
        unitPrice: 0,
        rate: 0.00,
        months: 0,
        listPrice: 0,
        price: 0,
      },
      {
        item: 'Service',
        unitPrice: 0,
        rate: 0.00,
        months: 0,
        listPrice: 0,
        price: 0,
      },
    ];

  service_mcOptTotal$ = new BehaviorSubject<any>(0); // accedo al valore del service e totale listprice machineOptions

  items$ = combineLatest(
    this.form.get('items').valueChanges.pipe(startWith([])) as Observable<AdditionalWarrantyItem[]>,
    this.recalculateItems$.pipe(startWith(() => { })),
    this.service_mcOptTotal$
  )
    .pipe(
      map(([items, _, service_mcOpt]) => {

        return items.map((item, i) => {
          item.unitPrice = service_mcOpt.total;
          if (service_mcOpt.service === 'distributor' && item.item === 'Service') {
            item.unitPrice = 0;
          }
          return this.calculateItem(item, i);
        });
      }),
    );

  hasDiscount$ = this.items$
    .pipe(
      map(items => {
        for (const item of items) {
          if (this.hasCustomDiscount(item)) {
            return true;
          }
        }
        return false;
      }),
    );

  constructor(title: string, public name: string) {
    super(title, name);
    this.recalculateItems$.next();
    this.registerRecalculateItems();
    this.hasDiscount$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => { this.updateColumns$.next(); });

  }

  registerRecalculateItems() {
    this.form.get('discount1').valueChanges.pipe(startWith(0))
      .subscribe(this.recalculateItems$);
  }

  protected itemToFormGroup(item: AdditionalWarrantyItem): FormGroup {
    const fg = new FormGroup({
      item: new FormControl(item.item),
      unitPrice: new FormControl(item.unitPrice),
      rate: new FormControl(item.rate),
      months: new FormControl(item.months),
      listPrice: new FormControl(item.listPrice),
      price: new FormControl(item.price),
    });

    if (item.discount1 !== undefined) {
      fg.addControl('discount1', this.createDiscountForm(item.discount1));
    }
    return fg;
  }


  protected createForm() {
    const basicForm = new FormGroup({
      name: new FormControl(),
      items: new FormArray([]),
      price: new FormControl(0),
      discount1: new FormControl(0),
      listPrice: new FormControl(0)

    });
    return basicForm;
  }

  protected calculateItem(item: AdditionalWarrantyItem, index): AdditionalWarrantyItem {
    item.rate = 0;
    if (item.months === 12) {
      item.rate = 0.60;
    }
    if (item.months === 24) {
      item.rate = 1;
    }

    const secDiscount = this.form.get('discount1').value;
    const hasDiscount = this.hasCustomDiscount(item);
    const discount1 = hasDiscount ? item.discount1 : secDiscount;
    const listPrice = item.months > 0 ? (item.unitPrice * (item.rate / 100) * item.months) / item.months : 0;

    item.listPrice = listPrice;
    item.price = listPrice * (1 - discount1 / 100);
    this.getItemControl(index, 'listPrice').patchValue(item.listPrice, { emitEvent: false });
    this.getItemControl(index, 'price').patchValue(item.price, { emitEvent: false });
    return item;
  }
  hasCustomDiscount(item: AdditionalWarrantyItem) {
    return item.discount1 !== undefined;
  }
  protected createDiscountForm(discount) {
    return new FormControl(discount, [Validators.min(0), Validators.max(100)]);
  }
  setCustomDiscount(item: AdditionalWarrantyItem) {
    if (!this.hasCustomDiscount(item)) {
      const index = this._items.indexOf(item);
      if (index !== -1) {
        const formArr = this.form.get('items') as FormArray;
        const itemGr = formArr.at(index) as FormGroup;
        itemGr.addControl('discount1', this.createDiscountForm(0));
      }
    }
  }

  removeCustomDiscount(item: AdditionalWarrantyItem) {
    if (this.hasCustomDiscount(item)) {
      const index = this._items.indexOf(item);
      if (index !== -1) {
        const formArr = this.form.get('items') as FormArray;
        const itemGr = formArr.at(index) as FormGroup;
        itemGr.removeControl('discount1');
      }
    }
  }

  protected calcColumns(): Observable<string[]> {
    for (const item of this._items) {
      if (this.hasCustomDiscount(item)) {
        return of(
          [
            'autext_description',
            'addwa_basicPrice',
            'addwa_rate',
            'addwa_months',
            'listPrice',
            'singlecs_discount',
            'price'
          ]
        );
      }
    }
    return of(
      [
        'autext_description',
        'addwa_basicPrice',
        'addwa_rate',
        'addwa_months',
        'listPrice',
        'price'
      ]
    );
  }
  footerRow(): string[] {
    return [];
  }
}

