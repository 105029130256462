import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-vm-edit-dialog',
  templateUrl: './vm-edit-dialog.component.html'
})
export class VMEditDialogComponent implements OnInit {
  vmForm = this.fb.group({
    name: ['', Validators.required],
    importFile: [undefined]
  });
  constructor(
    public dialogRef: MatDialogRef<VMEditDialogComponent>,
    protected fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    if (this.data) {
      this.vmForm.patchValue(this.data);
    } else {
      const fileControl = this.vmForm.get('importFile');
      fileControl.setValidators(Validators.required);
    }
  }



  onSave() {
    if (this.vmForm.valid) {
      const { name, importFile } = this.vmForm.value;
      const data = {
        name,
        picture: importFile && importFile.files && importFile.files[0] ? importFile.files[0] : null
      }
      this.dialogRef.close(data);
    }
  }

  onDismiss(): void {
    this.dialogRef.close();
  }
}
