import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-conf-ordine-dialog',
  
  template: `<h3>Order Confirmation</h3>
            <br />
            <form [formGroup]="confirmForm" (submit)="confirm()" (keydown.enter)="$event.preventDefault()">
              <mat-form-field style="width: 100%">
                <mat-label>Number</mat-label>
                <input matInput formControlName="matricola">
              </mat-form-field>
              <mat-form-field>
                <mat-label>Expected Delivery</mat-label>
                <input matInput [matDatepicker]="expectedDelivery" formControlName="expectedDelivery" tabindex="-1">
                <mat-datepicker-toggle matSuffix [for]="expectedDelivery"></mat-datepicker-toggle>
                <mat-datepicker #expectedDelivery></mat-datepicker>
              </mat-form-field>
              <div mat-dialog-actions>
                <button mat-raised-button color="primary" (click)="onNoClick()" type="button">Cancel</button>
                <button mat-raised-button color="warn" type="submit" cdkFocusInitial>
                    Save
                </button>
              </div>
            </form>`
})
export class ConfOrdineDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ConfOrdineDialogComponent>,
    protected fb: FormBuilder
  ) { }

  confirmForm = this.fb.group({
    matricola: ['', Validators.required],
    expectedDelivery: ['']
  });

  confirm() {
    if (this.confirmForm.invalid) { return; }
    this.dialogRef.close(
      {
        matricola: this.confirmForm.value.matricola,
        expectedDelivery: this.confirmForm.value.expectedDelivery ? this.confirmForm.value.expectedDelivery.add(12, 'h').toDate() : undefined
      }  
    );
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
}
