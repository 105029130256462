import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { isNil, omitBy } from 'lodash';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, mergeMap, startWith, switchMap } from 'rxjs/operators';
import { isObject } from 'util';
import { UserService } from '../users/users.service';
import { CustomerService } from '../_services/customer.service';
import { QuotationService } from '../_services/quotation.service';
import { ManagingAccountService } from './../_services/managing-account.service';
import { ConfirmDeleteDialogComponent } from './confirm-delete-dialog/confirm-delete-dialog.component';

interface IPaginator {
  page: number;
  limit: number;
}
@Component({
  selector: 'app-quotations',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.scss']
})
export class QuotationsComponent implements OnInit {
  constructor(
    protected quotationSrv: QuotationService,
    protected userSrv: UserService,
    protected customerSrv: CustomerService,
    protected managingSrv: ManagingAccountService,
    protected fb: FormBuilder,
    public dialog: MatDialog,
    protected router: Router
  ) { }

  filterForm = this.fb.group({
    customer: undefined,
    createdBy: undefined,
    status: undefined,
    createdAt: undefined,
    managingAccount: undefined
  });

  createdByFilter$ = this.filterForm.get('createdBy').valueChanges
    .pipe(
      map(val => {
        if (val && isObject(val)) {
          return val._id;
        } else {
          return undefined;
        }
      }),
      distinctUntilChanged()
    );

  customerFilter$ = this.filterForm.get('customer').valueChanges
    .pipe(
      map(val => {
        if (val && isObject(val)) {
          return val.name;
        } else {
          return undefined;
        }
      }),
      distinctUntilChanged()
    );

  managingAccountFilter$ = this.filterForm.get('managingAccount').valueChanges
    .pipe(
      map(val => {
        if (val && isObject(val)) {
          return val.name;
        } else {
          return undefined;
        }
      }),
      distinctUntilChanged()
    );

  filteredManaging$ = this.filterForm.get('managingAccount').valueChanges
    .pipe(
      debounceTime(300),
      mergeMap(val => {
        if (val) {
          return this.managingSrv.find({ name: val });
        } else {
          return of([]);
        }
      })
    );

  filteredCustomers$ = this.filterForm.get('customer').valueChanges
    .pipe(
      debounceTime(300),
      mergeMap(val => {
        if (val) {
          return this.customerSrv.find({ name: val });
        } else {
          return of([]);
        }
      })
    );

  filteredUsers$ = this.filterForm.get('createdBy').valueChanges
    .pipe(
      debounceTime(300),
      mergeMap(val => {
        if (val && typeof val === 'string') {
          return this.userSrv.find({ name: val }); /* .pipe(map(values => values.map(v => v.name))); */
        } else {
          return of([]);
        }
      })
    );

  listLength = 0;
  paginator$ = new BehaviorSubject<IPaginator>({ page: 0, limit: 25 });

  quotationsColumns = ['project', 'revision', 'customer', 'description', 'totalPrice', 'date', 'createdBy', 'status', 'actions'];
  quotations$ = combineLatest(
    this.createdByFilter$.pipe(startWith(undefined)),
    this.customerFilter$.pipe(startWith(undefined)),
    this.managingAccountFilter$.pipe(startWith(undefined)),
    this.filterForm.get('createdAt').valueChanges.pipe(startWith(undefined)),
    this.filterForm.get('status').valueChanges.pipe(startWith(undefined)),
    this.paginator$
  ).pipe(
    map(([createdBy, customer, managingAccount, createdAt, status]) => {
      return { createdBy, customer, managingAccount, createdAt, status };
    }),
    startWith({}),
    map(filters => {
      let filtersSelected = omitBy(filters, isNil);
      if (filtersSelected.createdAt) {
        filtersSelected.createdAt = filtersSelected.createdAt.toDate();
      }
      return filtersSelected = omitBy(filtersSelected, (a) => a === '');
    }),
    filter(val => !!val),
    switchMap(filters => {
      return this.quotationSrv.list({
        ...filters,
        ...this.paginator$.value
      });
    })
  );

  statusTypes = ['in progress', 'completed', 'approved'];

  ngOnInit() {
    this.quotationSrv.size()
      .subscribe(size => this.listLength = size);
  }

  statusChange(status, element) {
    this.quotationSrv.update(element._id, { status }).subscribe();
    element.status = status;
  }

  displayFn(user?: any): any | undefined {
    return user ? user.name : undefined;
  }

  resetControl(event: Event, controlName: string) {
    event.stopPropagation();
    this.filterForm.get(controlName).patchValue(undefined);
  }

  changePage(event) {
    this.paginator$.next({
      page: event.pageIndex,
      limit: event.pageSize
    })
  }

  deleteDialog(quotation) {
    this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '500px',
      data: {
        project: quotation.opportunity ? quotation.code : null,
        customer: quotation.opportunity ? quotation.opportunity.Company : null,
        descripton: quotation.opportunity ? quotation.opportunity.Description : null,
        createdBy: quotation.createdBy ? quotation.createdBy.name : null
      }
    }).afterClosed()
      .subscribe(result => {
        if (!result) { return; }
        this.quotationSrv.delete(quotation.id)
          .subscribe(_ => location.reload());
      });
  }

  copyQuotation(quotation) {
    this.quotationSrv.copy(quotation._id)
      .subscribe(result => {
        this.router.navigate(['quotations', result._id]);
      });
  }

  internalCopyQuotation(quotation) {
    this.quotationSrv.copy(quotation._id)
      .subscribe(result => {
        this.router.navigate(['quotations', result._id]);
      });
  }
}
