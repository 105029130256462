import { VmPicturesListComponent } from './anagrafiche/vm-pictures/vm-pictures.component';
import { VpDetailListComponent } from './anagrafiche/vp-detail/vp-detail.component';
import { AuthGuard } from './shared/auth.guard';
import { QuotationResolver } from './_services/quotation-resolver.service';
import { QuotationComponent } from './quotation/quotation.component';
import { AnagraficheComponent } from './anagrafiche/anagrafiche.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuotationsComponent } from './quotations/quotations.component';
import { RevisionsComponent } from './revisions/revisions.component';

const routes: Routes = [
  {
    path: 'anagrafiche',
    component: AnagraficheComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'datasets'
    }
  },
  {
    path: 'vp-details',
    component: VpDetailListComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'datasets'
    }
  },
  {
    path: 'vm-pictures',
    component: VmPicturesListComponent,
    canActivate: [AuthGuard],
    data: {
      role: 'datasets'
    }
  },
  {
    path: 'quotations',
    component: QuotationsComponent,
    canActivate: [AuthGuard],
    data: {
      role: ['quotations', 'am']
    }
  },
  {
    path: 'quotations/:id',
    component: QuotationComponent,
    canActivate: [AuthGuard],
    resolve: {
      quotation: QuotationResolver
    },
    data: {
      role: ['quotations', 'am']
    }
  },
  {
    path: 'quotations/:id/revisions',
    component: RevisionsComponent,
    canActivate: [AuthGuard],
    data: {
      role: ['quotations', 'am']
    }
  },
  {
    path: '',
    redirectTo: 'quotations',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
