import { Subject, Observable, of, combineLatest } from 'rxjs';
import { startWith, switchMap, map, takeUntil } from 'rxjs/operators';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { SectionItem, Section } from '../machineopt-increases/section-base';


export interface ServiceMaintenanceItem extends SectionItem {
    discount1?: number;
}

export class ServiceMaintenanceHelper extends Section<ServiceMaintenanceItem> {
    public hasOnlyOneDiscount = true;
    customItemForm = new FormGroup({
        item: new FormControl('', Validators.required),
        unitPrice: new FormControl()
    });

    protected recalculateItems$ = new Subject<void>();

    hasDiscount$ = this.items$
        .pipe(
            map(items => {
                for (const item of items) {
                    if (this.hasCustomDiscount(item)) {
                        return true;
                    }
                }
                return false;
            }),
        );


    constructor(title: string, public name: string) {
        super(title, name);
        this.registerRecalculateItems();
        this.hasDiscount$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => { this.updateColumns$.next(); });
    }

    registerRecalculateItems() {
        this.form.get('discount1').valueChanges.pipe(startWith(0))
            .pipe(takeUntil(this.destroyed$))
            .subscribe(this.recalculateItems$);
    }

    addNewItem() {
        if (this.customItemForm.invalid) {
            return;
        }
        const item: Partial<ServiceMaintenanceItem> = {
            price: 0,
            item: '',
            unitPrice: 0,
        };

        item.item = this.customItemForm.get('item').value;
        item.unitPrice = this.customItemForm.get('unitPrice').value;
        this.addItem(item);
        this.customItemForm.reset();
    }

    resetFields() {
        this.customItemForm.reset();
    }


    protected itemToFormGroup(item: ServiceMaintenanceItem): FormGroup {
        const fg = new FormGroup({
            item: new FormControl(item.item),
            unitPrice: new FormControl(item.unitPrice),
            listPrice: new FormControl(item.listPrice),
            price: new FormControl(item.price),
        });

        if (item.discount1 !== undefined) {
            fg.addControl('discount1', this.createDiscountForm(item.discount1));
        }
        return fg;
    }

    protected createForm() {
        const basicForm = new FormGroup({
            name: new FormControl(),
            items: new FormArray([]),
            price: new FormControl(0),
            discount1: new FormControl(0),
            listPrice: new FormControl(0)

        });
        return basicForm;
    }

    protected calculateItem(item: ServiceMaintenanceItem, index): ServiceMaintenanceItem {
        const secDiscount1 = this.form.get('discount1').value;
        const hasDiscount = this.hasCustomDiscount(item);
        const discount1 = hasDiscount ? item.discount1 : secDiscount1;
        item.listPrice = item.unitPrice;
        item.price = item.unitPrice * (1 - discount1 / 100);
        this.getItemControl(index, 'listPrice').patchValue(item.listPrice, {emitEvent: false});
        this.getItemControl(index, 'price').patchValue(item.price, {emitEvent: false});
        return item;
    }

    hasCustomDiscount(item: ServiceMaintenanceItem) {
        return item.discount1 !== undefined;
    }

    protected createDiscountForm(discount) {
        return new FormControl(discount, [Validators.min(0), Validators.max(100)]);
    }

    setCustomDiscount(item: ServiceMaintenanceItem) {
        if (!this.hasCustomDiscount(item)) {
            const index = this._items.indexOf(item);
            if (index !== -1) {
                const formArr = this.form.get('items') as FormArray;
                const itemGr = formArr.at(index) as FormGroup;
                itemGr.addControl('discount1', this.createDiscountForm(0));
            }
        }
    }

    removeCustomDiscount(item: ServiceMaintenanceItem) {
        if (this.hasCustomDiscount(item)) {
            const index = this._items.indexOf(item);
            if (index !== -1) {
                const formArr = this.form.get('items') as FormArray;
                const itemGr = formArr.at(index) as FormGroup;
                itemGr.removeControl('discount1');
            }
        }
    }

    protected calcColumns(): Observable<string[]> {
        for (const item of this._items) {
            if (this.hasCustomDiscount(item)) {
                return of(
                    ['autext_description',
                        'unitPrice',
                        'listPrice',
                        'singlecs_discount',
                        'price',
                        'actions']);

            }
        }
        return of(
            ['autext_description',
                'unitPrice',
                'price',
                'actions']
        );

    }
    footerRow(): string[] {
        return ['autext_description'];
    }

}
