import { RestoreRevisionDialogComponent } from './restore-revision-dialog/restore-revision-dialog.component';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth.service';
import { PageEvent, MatPaginatorIntl, MatDialog } from '@angular/material';
import { CustomPaginatorIntl } from './paginator-intl.service';
import { QuotationService } from '../_services/quotation.service';
import { SavingDialogComponent } from '../quotation/saving-dialog';

@Component({
  selector: 'app-revisions',
  templateUrl: './revisions.component.html',
  styleUrls: ['./revisions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginatorIntl }]
})
export class RevisionsComponent implements OnInit {
  quotationId: string;
  revisions = null;
  pdfUrl: string;
  docxUrl: string;
  currentRevision: any;
  page = 0;

  constructor(
    protected quotationSrv: QuotationService,
    protected activeRoute: ActivatedRoute,
    protected http: HttpClient,
    protected sanitizer: DomSanitizer,
    protected change: ChangeDetectorRef,
    protected authSrv: AuthService,
    protected dialog: MatDialog,
    protected router: Router) { }

  ngOnInit() {
    this.activeRoute.params.pipe(
      tap(params => {
        this.quotationId = params.id
      }),
      switchMap((params) => {
        return this.quotationSrv.getRevisions(params.id);
      }),
      tap(revisions => {
        this.revisions = revisions;
        this.updateUrl(this.revisions.length - 1);
      }),
    ).subscribe();
  }

  get userToken() {
    return this.authSrv.getToken();
  }

  updateUrl(index = 0) {
    this.currentRevision = this.revisions[index];
    this.page = index;
    console.log(this.currentRevision);
    this.pdfUrl = `/api/quotations/${this.currentRevision._id}/preventivo`;
    this.pdfUrl += `?access_token=${this.userToken}`;
    this.docxUrl = this.pdfUrl + '&docx=true'
    this.change.detectChanges();
  }

  promote() {
    const dialogRef = this.dialog.open(RestoreRevisionDialogComponent, {
      width: '500px'
    });

    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result) { return; } // close without save
        const quotId = this.quotationId;
        const revId = this.currentRevision._id;
        const revision = result.revision;
        const spinnerDialog = this.dialog.open(SavingDialogComponent)
        this.quotationSrv.promoteRevision(quotId, revId, revision)
          .subscribe(() => {
            // this.router.navigate(['quotations']);
            if (spinnerDialog) {
              spinnerDialog.close();
            }
            this.router.navigate(['quotations', quotId]);
          });
      });
  }
}
