import { VmPicturesListComponent } from './anagrafiche/vm-pictures/vm-pictures.component';
import { VpDetailListComponent } from './anagrafiche/vp-detail/vp-detail.component';
import { VersionsDialogComponent } from './quotation/quotation-versions/quotation-versions.component';
import { VMDialogComponent } from './quotation/vm-dialog/vm-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { ContextMenuModule } from 'ngx-contextmenu';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { AnagraficheComponent } from './anagrafiche/anagrafiche.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ConfOrdineDialogComponent } from './quotation/conf-ordine-dialog/conf-ordine-dialog.component';
import { CustomItemInput } from './quotation/custom-item-input/custom-item-input';
import { ListinoDialogComponent } from './quotation/listino-dialog/listino-dialog.component';
import { QuotationComponent } from './quotation/quotation.component';
import { RevisionDialogComponent } from './quotation/revision-dialog/revision-dialog.component';
import { SavingDialogComponent } from './quotation/saving-dialog';
import { TandemDialogComponent } from './quotation/tandem-dialog/tandem-dialog.component';
import { ConfirmDeleteDialogComponent } from './quotations/confirm-delete-dialog/confirm-delete-dialog.component';
import { QuotationsComponent } from './quotations/quotations.component';
import { SharedModule } from './shared/shared.module';
import { StatusSelectComponent } from './shared/status-select/status-select.component';
import { UsersModule } from './users/users.module';
import { InternalConfirmationDialogComponent } from './quotation/internal-confirmation/internal-confirmation.component';
import { VPEditDialogComponent } from './anagrafiche/vp-detail/vp-edit-dialog/vp-edit-dialog.component';
import { VMEditDialogComponent } from './anagrafiche/vm-pictures/vm-edit-dialog/vm-edit-dialog.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { RevisionsComponent } from './revisions/revisions.component';
import { RestoreRevisionDialogComponent } from './revisions/restore-revision-dialog/restore-revision-dialog.component';
import { ConflictDialogComponent } from './quotation/conflict-dialog/conflict-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    AnagraficheComponent,
    VpDetailListComponent,
    VmPicturesListComponent,
    QuotationComponent,
    QuotationsComponent,
    CustomItemInput,
    RevisionDialogComponent,
    RestoreRevisionDialogComponent,
    ListinoDialogComponent,
    VMDialogComponent,
    StatusSelectComponent,
    ConfOrdineDialogComponent,
    TandemDialogComponent,
    SavingDialogComponent,
    ConfirmDeleteDialogComponent,
    VersionsDialogComponent,
    InternalConfirmationDialogComponent,
    VPEditDialogComponent,
    VMEditDialogComponent,
    RevisionsComponent,
    ConflictDialogComponent
  ],
  imports: [
    SharedModule,
    ContextMenuModule.forRoot(),
    MaterialFileInputModule,
    UsersModule,
    AuthModule,
    AppRoutingModule,
    DragDropModule,
    PdfViewerModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    RevisionDialogComponent,
    ListinoDialogComponent,
    VMDialogComponent,
    ConfOrdineDialogComponent,
    TandemDialogComponent,
    SavingDialogComponent,
    ConfirmDeleteDialogComponent,
    VersionsDialogComponent,
    InternalConfirmationDialogComponent,
    VPEditDialogComponent,
    VMEditDialogComponent,
    RestoreRevisionDialogComponent,
    ConflictDialogComponent
  ]
})
export class AppModule { }
