import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isString, reduce } from 'lodash';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { VpService } from './vp.service';

@Injectable({ providedIn: 'root' })
export class ListinoService {

  constructor(
    protected http: HttpClient,
    protected vpSrv: VpService) { }

  list(deleted?: boolean): Observable<any[]> {
    const api = deleted ? '/listini?deleted=true' : '/listini';
    return this.http.get<any[]>(api);
  }

  get(id: string, deleted?: boolean): Observable<any> {
    const api = deleted ? `/listini/${id}?deleted=true` : `/listini/${id}`;
    return this.http.get<any>(api);
  }

  remove(id: string): Observable<any> {
    return this.http.delete<any>(`/listini/${id}`);
  }

  getVMs(listino: string | any): Observable<string[]> {
    if (isString(listino)) {
      return this.get(listino as string, true)
        .pipe(
          map(items => {
            return items.vms.map(vm => vm.name);
          }),
          map(vmsNames => {
            /*
              NATURAL SORT
              https://stackoverflow.com/questions/2802341/javascript-natural-sort-of-alphanumerical-strings
              https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
            */
            const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
            return vmsNames.sort((a, b) => collator.compare(a, b));
          })
        );
    } else {
      return of((listino as any).vms.map(vm => vm.name));
    }
  }

  getVM(listino: string, vmName: string) {
    return this.http.get(`/listini/${listino}/${encodeURIComponent(vmName)}`);
  }

  getStandardVPs(vm: any) {
    const vps: any[] = vm.vps;
    const standard = vps.filter(vp => vp.specs.standard);
    return this.mergeVPsData(standard)
      .pipe(
        map(list => {
          list.forEach(item => {
            item.uid = `${vm.name}_${item.vp}`;
            item.vm = vm.name;
          })
          return list;
        })
      );
  }

  getVPs(vm: any) {
    const vps: any[] = vm.vps;
    return this.mergeVPsData(vps)
      .pipe(
        map(list => {
          list.forEach(item => {
            item.uid = `${vm.name}_${item.vp}`;
            item.vm = vm.name;
          })
          return list;
        })
      )
  }

  import(name: string, kind: string, importFile: any, defaultListino: boolean): Observable<any> {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('kind', kind);
    formData.append('import', importFile);
    formData.append('default', defaultListino.toString());
    return this.http.post('/listini/import', formData);
  }

  update(id: string, defaultListino: boolean, importFile?: any): Observable<any> {
    const formData = new FormData();
    formData.append('import', importFile);
    formData.append('default', defaultListino ? defaultListino.toString() : null);
    return this.http.put(`/listini/${id}`, formData);
  }

  protected mergeVPsData(vps): Observable<any[]> {
    return this.vpSrv.list()
      .pipe(
        map(items => {
          const mapped = reduce(items, (result, current) => {
            result[current.name] = {
              description: current.languages.IT,
              note: current.note
            };
            return result;
          }, {});
          return vps.map(vp => {
            const match = mapped[vp.vp];
            return {
              ...vp,
              ...match
            };
          });
        })
      );
  }
}
