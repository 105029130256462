import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of, } from 'rxjs';
import { take, map, catchError } from 'rxjs/operators';

import { QuotationService } from './quotation.service';

@Injectable({
  providedIn: 'root',
})
export class QuotationResolver implements Resolve<any> {
  constructor(private srv: QuotationService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Observable<never> {
    const id = route.paramMap.get('id');
    if (id === 'new') {
      return of({});
    }
    return this.srv.get(id)
      .pipe(
        take(1),
        catchError(_ => {
          this.router.navigate(['/quotations']);
          return of(null);
        })
      );
  }
}
