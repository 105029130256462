import { FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { Observable, combineLatest, ReplaySubject, merge, of } from 'rxjs';
import { map, startWith, filter, tap, takeUntil } from 'rxjs/operators';
import { isString, sortBy } from 'lodash';
import { SectionItem, Section } from './section-base';

export interface VpItem extends SectionItem {
  discount1?: number;
  discount2?: number;
  discount3?: number;
  uid: string;
  vm: string;
  hasRuleErrors?: boolean;
}

export class VpSectionHelper extends Section<VpItem> {
  protected static dropCompatibility: string[];

  protected machines = [];


  get hasTandem() {
    return this.machines.length > 1;
  }

  get dropConnectedTo() {
    return VpSectionHelper.dropCompatibility;
  }

  addControl = new FormControl();

  customItemForm = new FormGroup({
    item: new FormControl('', Validators.required),
    description: new FormControl(''),
    unitPrice: new FormControl(),
    listPrice: new FormControl(),
    vm: new FormControl('')
  });


  // tslint:disable-next-line:variable-name
  protected _showAddNew = false;
  get showAddNew(): boolean {
    return this._showAddNew;
  }


  protected optionsSource$ = new ReplaySubject<any>(1);
  protected freeAdd = false;

  addOptions$ = combineLatest(
    this.optionsSource$,
    this.addControl.valueChanges.pipe(startWith(''))
  )
    .pipe(
      map(([vps, formValue]) => [vps, formValue ? formValue : '']),
      filter(([_, formValue]) => isString(formValue)),
      map(([vps, formValue]) => {
        formValue = formValue.toLowerCase();
        if (!formValue) {
          return vps;
        }
        return vps.filter(vp => {
          const itemName: string = vp.item.toLowerCase();
          const itemDescription: string = vp.description ? vp.description.toLowerCase() : '';
          return itemName.includes(formValue) || itemDescription.includes(formValue);
        });
      }),
      map(vps => sortBy(vps, 'item', 'vm')),
      map(vps => {
        return this.freeAdd ? [{ item: 'Manual item', kind: 'add_new' }].concat(vps) : vps;
      })
    );

  title: string;
  hasDiscount$ = this.items$
    .pipe(
      map(items => {
        for (const item of items) {
          if (this.hasCustomDiscount(item)) {
            return true;
          }
        }
        return false;
      }),
    );

  // tslint:disable-next-line:variable-name
  protected _items: VpItem[] = [];

  constructor(title: string, name: string, freeAdd = true) {
    super(title, name);
    if (!VpSectionHelper.dropCompatibility) {
      VpSectionHelper.dropCompatibility = [];
    }
    VpSectionHelper.dropCompatibility.push(name);

    this.freeAdd = freeAdd;
    this.registerRecalculateItems();
    this.hasDiscount$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => { this.updateColumns$.next(); });

  }

  registerRecalculateItems() {
    // ogni volta che un discount cambia viene ricalcolato il prezzo del singolo elemento
    merge(
      this.form.get('discount1').valueChanges.pipe(startWith(0)),
      this.form.get('discount2').valueChanges.pipe(startWith(0)),
      this.form.get('discount3').valueChanges.pipe(startWith(0))
    ).pipe(takeUntil(this.destroyed$))
      .subscribe(this.recalculateItems$);
  }

  toggleAddNew() {
    this._showAddNew = !this._showAddNew;
    this.customItemForm.reset();
  }

  getItemsChange() {
    const formArr = this.form.get('items') as FormArray;

    return formArr.at(0);
  }

  addItem(item: VpItem) {
    this.addControl.patchValue('');
    if (item.kind === 'add_new') {
      this.toggleAddNew();
      return;
    }
    super.addItem(item);
  }

  removeItem(item:VpItem);
  removeItem(uid: string);
  removeItem(a: VpItem | string) {
    if (typeof a === 'string' ) {
      a = this._items.find(i => i.uid === a);
    }
    super.removeItem(a);
  }

  addNewItem() {
    if (this.customItemForm.invalid) {
      return;
    }
    const item: VpItem = {
      toBeDefined: false,
      toCheck: false,
      standard: false,
      kind: 'vp',
      qty: 1,
      note: '',
      price: 0,
      item: '',
      uid: '',
      vm: '',
      description: '',
      unitPrice: 0,
      listPrice: 0
    };

    item.item = this.customItemForm.get('item').value;
    item.unitPrice = this.customItemForm.get('unitPrice').value;
    item.description = this.customItemForm.get('description').value;
    if (this.customItemForm.get('vm').value) {
      item.vm = this.customItemForm.get('vm').value;
    } else {
      item.vm = this.machines[0];
    }
    item.uid = `${item.vm}_${item.item}`;
    this.toggleAddNew();
    this.addItem(item);
    this.customItemForm.reset();
  }

  hasCustomDiscount(item: VpItem) {
    return item.discount1 !== undefined || item.discount2 !== undefined || item.discount3 !== undefined;
  }

  setCustomDiscount(item: VpItem) {
    if (!this.hasCustomDiscount(item)) {
      const index = this._items.indexOf(item);
      if (index !== -1) {
        const formArr = this.form.get('items') as FormArray;
        const itemGr = formArr.at(index) as FormGroup;
        itemGr.addControl('discount1', this.createDiscountForm(0));
        itemGr.addControl('discount2', this.createDiscountForm(0));
        itemGr.addControl('discount3', this.createDiscountForm(0));

      }
    }
  }

  removeCustomDiscount(item: VpItem) {
    if (this.hasCustomDiscount(item)) {
      const index = this._items.indexOf(item);
      if (index !== -1) {
        const formArr = this.form.get('items') as FormArray;
        const itemGr = formArr.at(index) as FormGroup;
        itemGr.removeControl('discount1');
        itemGr.removeControl('discount2');
        itemGr.removeControl('discount3');
      }
    }
  }

  /**
   * Aggiorna i dati delle section se comuni tra loro
   * Es: vp condivise tra section... se già scelta in un altra
   * section non posso sceglierla
   * @param items new data
   */
  updateOptionsSource(items: any[]) {
    this.optionsSource$.next(items);
  }

  protected createForm() {
    const baseForm = super.createForm();
    baseForm.addControl('discount1', new FormControl(0));
    baseForm.addControl('discount2', new FormControl(0));
    baseForm.addControl('discount3', new FormControl(0));
    baseForm.addControl('vm', new FormControl(''));
    baseForm.addControl('uid', new FormControl(''));
    return baseForm;
  }

  itemToFormGroup(item: VpItem) {
    const fg = super.itemToFormGroup(item);
    fg.addControl('vm', new FormControl(item.vm))
    fg.addControl('uid', new FormControl(`${item.vm}_${item.item}`));
    if (item.discount1 !== undefined || item.discount2 !== undefined || item.discount3 !== undefined) {
      fg.addControl('discount1', this.createDiscountForm(item.discount1));
      fg.addControl('discount2', this.createDiscountForm(item.discount2));
      fg.addControl('discount3', this.createDiscountForm(item.discount3));
    }
    return fg;
  }

  setMachines(machines: string[]) {
    this.machines = machines;
    this.updateColumns$.next();
  }

  clear(vm?: string) {
    if (!vm) {
      super.clear();
      return;
    }
    const filtered = this.items.filter(i => i.vm !== vm && i.item !== vm);
    super.clear();
    for (let item of filtered) {
      this.addItem(item);
    }
  }

  protected compatibilityErrors = [];
  updateRuleErrors(erros: any[]) {
    this.compatibilityErrors = erros;
  }

  getError(uid: string) {
    return this.compatibilityErrors.find(i => i.uid === uid);
  }

  hasError(uid: string) {
    return !! this.getError(uid);
  }

  protected createDiscountForm(discount) {
    return new FormControl(discount, [Validators.min(0), Validators.max(100)]);
  }

  protected calculateItem(item: VpItem, index): VpItem {
    const secDiscount1 = this.form.get('discount1').value;
    const secDiscount2 = this.form.get('discount2').value;
    const secDiscount3 = this.form.get('discount3').value;

    const hasDiscount = this.hasCustomDiscount(item);
    const discount1 = hasDiscount ? item.discount1 : secDiscount1;
    const discount2 = hasDiscount ? item.discount2 : secDiscount2;
    const discount3 = hasDiscount ? item.discount3 : secDiscount3;

    item.listPrice = item.unitPrice * item.qty;
    item.price = item.listPrice * (1 - discount1 / 100) * (1 - discount2 / 100) * (1 - discount3 / 100);
    item.uid = `${item.vm}_${item.item}`;
    this.getItemControl(index, 'listPrice').patchValue(item.listPrice, {emitEvent: false});
    this.getItemControl(index, 'price').patchValue(item.price, {emitEvent: false});
    return item;
  }

  protected calcColumns(): Observable<string[]> {
    let columns = ['item'];
    if (this.hasTandem) {
      columns = columns.concat(['vm']);
    }
    columns = columns.concat(['description', 'note']);
    for (const item of this._items) {
      if (this.hasCustomDiscount(item)) {
        columns = columns.concat('discount');
      }
    }
    columns = columns.concat(['unitPrice', 'qty', 'listPrice', 'price', 'actions']);
    return of(columns);
  }

  protected footerRow(): string[] {
    return this.hasTandem ? ['item', 'vm', 'description', 'note'] : ['item', 'description', 'note'];
  }

}
