import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { ListinoService } from 'src/app/_services/listino.service';
import { Observable, pipe, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-listino-dialog',
  templateUrl: './listino-dialog.component.html',
  styleUrls: ['./listino-dialog.component.scss']
})
export class ListinoDialogComponent implements OnInit, OnDestroy {
  listini$: Observable<any[]>;
  protected destroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ListinoDialogComponent>,
    protected fb: FormBuilder,
    protected listinoSrv: ListinoService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  priceListForm = this.fb.group({
    listino: ['', Validators.required],
  });

  ngOnInit() {
    this.listini$ = this.listinoSrv.list();
    this.listini$
      .pipe(
        takeUntil(this.destroy$),
        filter(_ => this.data && this.data.selectedListino)
      )
      .subscribe((listini) => {
        const selected = listini.find((el) => (el.name === this.data.selectedListino.name) && (el.kind === this.data.selectedListino.kind));
        if (selected) {
          this.priceListForm.get('listino').patchValue(selected._id);
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }



  changeListino() {
    if (this.priceListForm.invalid) { return; }

    const idListino = this.priceListForm.value.listino;
    this.dialogRef.close({ idListino });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
