import { VmPictureService } from './../_services/vm-picture.service';
import { InternalConfirmationDialogComponent } from './internal-confirmation/internal-confirmation.component';
import { AclService } from './../shared/acl.service';
import { VersionsDialogComponent } from './quotation-versions/quotation-versions.component';
// tslint:disable: variable-name
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { differenceBy, isNil, orderBy } from 'lodash';
import { combineLatest, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, delay, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CorseLuceService } from '../_services/corse-luce.service';
import { DocumentationLanguageService } from '../_services/documentation-language.service';
import { InstallationService } from '../_services/installation.service';
import { LanguagesService } from '../_services/languages.service';
import { ListinoService } from '../_services/listino.service';
import { OpportunityService } from '../_services/opportunity.service';
import { QuotationService } from '../_services/quotation.service';
import { StandardElectricService } from '../_services/standard-electric.service';
import { ConfOrdineDialogComponent } from './conf-ordine-dialog/conf-ordine-dialog.component';
import { ListinoDialogComponent } from './listino-dialog/listino-dialog.component';
import { VMDialogComponent } from './vm-dialog/vm-dialog.component';
import { RevisionDialogComponent } from './revision-dialog/revision-dialog.component';
import { SavingDialogComponent } from './saving-dialog';
import {
  AdditionalWarrantyHelper, AutomationExternalSectionHelper, DocumentsHelper,
  InstallationSectionHelper, PaymentsHelper, PreOwnedHelper, Section, ServiceMaintenanceHelper,
  ToolsSectionHelper, TransportHelper, VpItem, VpSectionHelper
} from './sections';
import { TandemDialogComponent } from './tandem-dialog/tandem-dialog.component';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ExtraInstallationSectionHelper } from './sections/services-sec/extra-installation-section';
import { VpService } from '../_services/vp.service';
import { ConflictDialogComponent } from './conflict-dialog/conflict-dialog.component';

@Component({
  selector: 'app-quotation',
  templateUrl: './quotation.component.html',
  styleUrls: ['./quotation.component.scss'],
  providers: []
})
export class QuotationComponent implements OnInit, OnDestroy {
  protected quotationChange$ = new ReplaySubject(1);
  title = 'New Quotation';
  mcHelper = new VpSectionHelper('Machine Configuration', 'machineConfiguration', true);
  optionsHelper = new VpSectionHelper('Options', 'options');
  softwareOfflineHelper = new VpSectionHelper('Software Offline', 'softwareOffline', true);
  toolsHelper = new ToolsSectionHelper('Tools', 'tools', true);
  automationExternalHelper = new AutomationExternalSectionHelper('Automation External', 'automationExternal', false);
  // il prezzo della section non è incluso nel totale quotation
  increasesHelper = new VpSectionHelper('Increases for other options', 'increases', true);
  installationHelper = new InstallationSectionHelper('Installation', 'installation');
  extraInstallationHelper = new ExtraInstallationSectionHelper('Extra Installation', 'extra_installation');
  additionalWarrantyHelper = new AdditionalWarrantyHelper('Additional Warranty (over 12 months)', 'additionalWarranty');
  serviceMaintenanceHelper = new ServiceMaintenanceHelper('Service & Maintenance', 'serviceMaintenance');
  preOwnedHelper = new PreOwnedHelper('Pre-owned Machines', 'preOwned');
  documentsHelper = new DocumentsHelper('Documents', 'documents');
  transportHelper = new TransportHelper('Transport', 'transport');
  paymentsHelper = new PaymentsHelper('Payments', 'payments');

  machineOptSections = [
    this.mcHelper,
    this.optionsHelper,
    this.softwareOfflineHelper,
    this.toolsHelper,
    this.automationExternalHelper
  ];

  servicesSections = [
    this.installationHelper,
    this.extraInstallationHelper,
    this.additionalWarrantyHelper,
    this.serviceMaintenanceHelper,
    this.transportHelper
  ];

  groups: any[] = [
    {
      title: 'Machine and Options',
      showPrice: true,
      sections: this.machineOptSections,
      listPrice$: 0,
      sortable: true
    }, {
      title: 'Services',
      showPrice: true,
      sections: this.servicesSections,
      listPrice$: 0
    }, {
      title: 'Increases for other options (not included in the offer)',
      showPrice: true,
      skipPrice: true,
      skipTotalPrice: true,
      sections: [this.increasesHelper],
      listPrice$: 0,
      sortable: true
    }, {
      title: 'Pre-owned Machines',
      showPrice: true,
      skipPrice: true,
      sections: [this.preOwnedHelper],
      listPrice$: 0
    }, {
      title: 'Payments',
      showPrice: false,
      skipPrice: true,
      sections: [this.paymentsHelper],
      listPrice$: 0
    }, {
      title: 'Documents',
      showPrice: false,
      skipPrice: true,
      sections: [this.documentsHelper],
      listPrice$: 0
    }
  ];

  quotationForm = this.fb.group({
    quotationLanguage: ['en', Validators.required],
    documentationLanguage: ['', Validators.nullValidator],
    corsaLuce: '',
    standardElectric: '',
    deliveryTime: ['(shipment from Italy)', Validators.required],
    months: [6, Validators.required],
    commission: [0, [Validators.min(0), Validators.max(100)]],
    validity: [30, [Validators.min(0)]],
    service: ['', Validators.required],
    listino: ['', Validators.required],
    vm: ['', Validators.required],
    price: 0,
    listPrice: 0,
    machineConfiguration: this.mcHelper.form,
    options: this.optionsHelper.form,
    softwareOffline: this.softwareOfflineHelper.form,
    tools: this.toolsHelper.form,
    automationExternal: this.automationExternalHelper.form,
    increases: this.increasesHelper.form,
    installation: this.installationHelper.form,
    extraInstallation: this.extraInstallationHelper.form,
    additionalWarranty: this.additionalWarrantyHelper.form,
    serviceMaintenance: this.serviceMaintenanceHelper.form,
    preOwned: this.preOwnedHelper.form,
    documents: this.documentsHelper.form,
    notes: this.fb.array([]),
    notesExternal: this.fb.array([]),
    payments: this.paymentsHelper.form,
    transport: this.transportHelper.form,
    opportunity: '',
    revision: null,
    comments: [],
    onlyNet: false,
    customPrice: 0,
    matricola: '',
    machinePicture: ['', Validators.required],
    hasTandem: [false],
    tandemVM: [''], // TO-DO required se hasTandem = true
    tandemCorsaLuce: '',
  });

  _listini = [];

  docLanguages$ = this.documentationLanguageSrv.list();
  languages$ = this.languagesSrv.list();
  corseLuce$ = this.corseLuceSrv.list();
  standardElectrics$ = this.standardElectricSrv.list();

  destroyed$ = new Subject();
  listini$ = new Subject<any[]>();
  vms$ = new Subject<any[]>();
  vmPictures$ = this.machinePictureSrv.list();

  selectedVM$ = combineLatest([
    this.quotationForm.get('listino').valueChanges,
    this.quotationForm.get('vm').valueChanges,
    this.vms$.pipe(startWith([]))
  ]).pipe(
    takeUntil(this.destroyed$),
    filter(([_, vmName, vms]) => vms.find(vm => vm === vmName)),
    tap(([_, vmName]) => { this.installationHelper.vmSelected$.next(vmName); }),
    switchMap(([listinoId, vmName]) => {
      return (listinoId && vmName) ? this.listinoSrv.getVM(listinoId, vmName) : of(null);
    }),
  );

  selectedTandemVM$ = combineLatest([
    this.quotationForm.get('listino').valueChanges,
    this.quotationForm.get('tandemVM').valueChanges,
    this.vms$.pipe(startWith([]))
  ]).pipe(
    takeUntil(this.destroyed$),
    filter(([_, vmName, vms]) => vms.find(vm => vm === vmName)),
    switchMap(([listinoId, vmName]) => {
      return (listinoId && vmName) ? this.listinoSrv.getVM(listinoId, vmName) : of(null);
    }),
  );

  opportunity$ = new ReplaySubject<any>(1); // = this.opportunitySrv.getOpportunity('d290f1ee-6c54-4b01-90e6-d701748f0851');

  primaryVps$ = this.selectedVM$
    .pipe(
      switchMap(vm => vm ? this.listinoSrv.getVPs(vm) : of([]))
    );

  tandemVps$ = this.selectedTandemVM$
    .pipe(
      switchMap(vm => vm ? this.listinoSrv.getVPs(vm) : of([]))
    );

  vps$ = combineLatest([
    this.primaryVps$.pipe(startWith([])),
    this.tandemVps$.pipe(startWith([]))
  ])
  .pipe(
    map(([primary, tandem]) => primary.concat(tandem)),
    map(vps => vps.map(vp => this.createMachineConfigurationFromVP(vp))),
    map(items => orderBy(items, 'uid') as any[])
  )

  machineConfigurationPrice$ = this.mcHelper.price$;
  options$ = this.optionsHelper.items$;
  softwareOffline$ = this.softwareOfflineHelper.items$;
  tools$ = this.toolsHelper.items$;
  automationExternal$ = this.automationExternalHelper.items$;
  increases$ = this.increasesHelper.items$;
  installation$ = this.installationHelper.items$;
  extraInstallation$ = this.installationHelper.items$;
  additionalWarranty$ = this.additionalWarrantyHelper.items$;
  serviceMaintenance$ = this.serviceMaintenanceHelper.items$;
  preOwned$ = this.preOwnedHelper.items$;
  documents$ = this.documentsHelper.items$;
  payments$ = this.paymentsHelper.items$;
  transport$ = this.transportHelper.items$;

  totalPriceMachineOpts$ =
    combineLatest(...this.machineOptSections.map(sec => sec.price$))
      .pipe(
        map(prices => prices.reduce((prev, curr) => curr + prev, 0)),
        startWith(0)
      );

  totalPriceServicesSections$ =
    combineLatest(...this.servicesSections.map(sec => sec.price$))
      .pipe(
        map((prices) => prices.reduce((prev, curr) => curr + prev, 0)),
        startWith(0),
      );

  totalQuotation$: Observable<number>;
  totalListPrice$: Observable<number>;

  discount$: Observable<number>;

  protected numOfMachines = 0;

  quotation: any;

  protected alreadyHasTandem$ = new Subject<void>();

  protected loadQuotation$ = this.activatedRoute.data
    .pipe(
      map(({ quotation }) => quotation),
      filter(quotation => !!quotation._id),
      tap(qt => {
        this.quotation = qt;
        if (qt.hasTandem) {
          this.alreadyHasTandem$.next();
        }
        this.quotationChange$.next(qt);
      })
    );

  protected newQuotation$ = this.activatedRoute.data
    .pipe(
      map(({ quotation }) => quotation),
      filter(quotation => !quotation._id),
      tap(qt => {
        this.quotation = qt;
        this.quotationChange$.next(qt);
      })
    );

  protected loadMachineConfiguration$ = this.loadQuotation$
    .pipe(
      tap(quotation => this.title = this.title.replace('New Quotation', 'Quotation')),
      map(quotation => quotation.sections.find(section => section.name === 'machineConfiguration')),
      filter(options => !!options)
    );

  protected loadOptions$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'options')),
      filter(options => !!options)
    );

  protected loadSoftwareOffline$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'softwareOffline')),
      filter(softOf => !!softOf)
    );

  protected loadTools$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'tools')),
      filter(tools => !!tools)
    );

  protected loadAutomationExternal$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'automationExternal')),
      filter(authExt => !!authExt)
    );

  protected loadIncreases$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'increases')),
      filter(increases => !!increases)
    );

  protected loadInstallation$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'installation')),
      filter(installation => !!installation)
    );

  protected loadExtraInstallation$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'extra_installation')),
      filter(installation => !!installation)
    );

  protected loadAdditionalWarranty$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'additionalWarranty')),
      filter(additionalWarranty => !!additionalWarranty)
    );

  protected loadServiceMaintenance$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'serviceMaintenance')),
      filter(serviceMaintenance => !!serviceMaintenance)
    );

  protected loadPreOwned$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'preOwned')),
      filter(preOwned => !!preOwned)
    );

  protected loadDocuments$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'documents')),
      filter(documents => !!documents)
    );

  protected loadPayments$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'payments')),
      filter(payments => !!payments)
    );

  protected loadTransport$ = this.loadQuotation$
    .pipe(
      map(quotation => quotation.sections.find(section => section.name === 'transport')),
      filter(transport => !!transport)
    );

  protected quotationId: string = null;

  protected aclState$ = this.aclSrv.aclState;

  protected isAm$ = this.aclState$
    .pipe(
      map(state => state.hasPermission('am') && !state.hasPermission('quotation'))
    );

  isQuotationOrCreator$ = this.quotationChange$
      .pipe(
        switchMap(() => this.aclState$),
        map(aclState => {
          return aclState.hasPermission('quotations') || (this.quotation._id && aclState.checkId(this.quotation.createdBy._id));
        })
      )

  filteredVms$ = new ReplaySubject<any[]>(1);

  machines = [];
  prevVMName = '';
  prevTandemVMName = '';

  protected prevPrice = null;
  protected prevCustomPrice = null;

  protected vpDefinition$ = this.vpSrv.list();

  constructor(
    protected fb: FormBuilder,
    protected languagesSrv: LanguagesService,
    protected corseLuceSrv: CorseLuceService,
    protected standardElectricSrv: StandardElectricService,
    protected documentationLanguageSrv: DocumentationLanguageService,
    protected installationSrv: InstallationService,
    protected listinoSrv: ListinoService,
    protected machinePictureSrv: VmPictureService,
    protected quotationSrv: QuotationService,
    protected opportunitySrv: OpportunityService,
    protected activatedRoute: ActivatedRoute,
    protected vpSrv: VpService,
    protected router: Router,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private aclSrv: AclService) { }

  ngOnInit() {
    this.activatedRoute.queryParams
      .pipe(
        filter(params => !!params.opportunity),
        map(params => params.opportunity),
        switchMap(id => this.opportunitySrv.getOpportunity(id)),
        takeUntil(this.destroyed$)
      )
      .subscribe(opp => this.opportunity$.next(opp));

    this.loadQuotation$
      .pipe(
        filter(quotation => quotation.opportunity),
        map(quotation => quotation.opportunity.Id),
        switchMap(id => this.opportunitySrv.getOpportunity(id)),
        takeUntil(this.destroyed$)
      )
      .subscribe(opp => this.opportunity$.next(opp));

    this.installationSrv.list()
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(values => this.installationHelper.installationsSpecs$.next(values));

    this.installationSrv.getManPrices_it()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(values => this.installationHelper.installationManPricesIt$.next(values));

    this.installationSrv.getManPrices_fc()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(values => this.installationHelper.installationManPricesFc$.next(values));

    this.installationSrv.getParameters()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(values => this.installationHelper.installationParameters$.next(values));

    this.quotationForm.get('listino').valueChanges
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(value =>
          value
            ? this.listinoSrv.getVMs(value)
            : of([]))
      )
      .subscribe(this.vms$);

    combineLatest([
      merge(
        this.quotationForm.get('vm').valueChanges.pipe(startWith('')),
        this.quotationForm.get('tandemVM').valueChanges.pipe(startWith(''))
      ),
      this.vms$.pipe(startWith([]))
    ])
      .pipe(
        takeUntil(this.destroyed$),
        map(([filt, vms]) => {
          if (typeof (vms) === 'string') {
            return [vms];
          } else {
            if (!filt) {
              return vms;
            }
            const vm_list: string[] = vms;
            return vm_list.filter(opt => opt.toLowerCase().includes(filt));
          }
        })
      )
      .subscribe(items => this.filteredVms$.next(items));

    this.groups.forEach(group => {
      group.price$ = combineLatest(...group.sections.map(sec => sec.price$))
        .pipe(
          map((prices) => prices.reduce((prev, curr) => curr + prev), 0),
          startWith(0)
        );

      group.listPrice$ = combineLatest(...group.sections.map(sec => sec.listPrice$))
        .pipe(
          map((prices) => prices.reduce((prev, curr) => curr + prev, 0)),
          startWith(0)
        );
    });

    this.totalQuotation$ = combineLatest(...this.groups.filter(g => !g.skipPrice)
      .map(g => g.price$))
      .pipe(
        map((prices) => prices.reduce((prev, curr) => curr + prev, 0)),
        startWith(0)
      );

    this.totalListPrice$ = combineLatest(...this.groups.filter(g => g.listPrice$ && !g.skipTotalPrice)
      .map(g => g.listPrice$))
      .pipe(
        map((prices) => prices.reduce((prev, curr) => curr + prev, 0)),
        startWith(0)
      );

    this.opportunity$.subscribe(opportunity => {
      this.quotationForm.get('opportunity').patchValue(opportunity);
      this.quotation.opportunity = opportunity;
      this.title = `${opportunity.Company} - ${opportunity.Description}`;
    });

    /** Quotation prices */
    this.totalQuotation$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(price => this.quotationForm.get('price').patchValue(price, { onlySelf: true }));

    this.totalListPrice$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(price => {
        this.quotationForm.get('listPrice').patchValue(price, { onlySelf: true });
      });

    /*
    * imposta le vp negli helper, sono le opzioni selezionabili
    * aggiungere al subscribe e al combinelatest gli altri helper in futuro
    */
    combineLatest(
      this.vps$,
      this.mcHelper.items$.pipe(startWith([])),
      this.optionsHelper.items$.pipe(startWith([])),
      this.softwareOfflineHelper.items$.pipe(startWith([])),
      this.toolsHelper.items$.pipe(startWith([])),
      this.increasesHelper.items$.pipe(startWith([])),
    ).pipe(
      takeUntil(this.destroyed$),
      map(([vps, mcs, options, softOf, tools, increases]) => {
        return differenceBy(vps, mcs, options, softOf, tools, increases, 'uid');
      })
    ).subscribe(items => {
      this.optionsHelper.updateOptionsSource(items);
      this.mcHelper.updateOptionsSource(items);
      this.softwareOfflineHelper.updateOptionsSource(items);
      this.toolsHelper.updateOptionsSource(items);
      this.increasesHelper.updateOptionsSource(items);
    });

    combineLatest(
      this.vpDefinition$,
      this.vps$,
      this.mcHelper.items$.pipe(startWith([])),
      this.optionsHelper.items$.pipe(startWith([])),
      this.softwareOfflineHelper.items$.pipe(startWith([])),
      this.toolsHelper.items$.pipe(startWith([])),
    ).pipe(
      takeUntil(this.destroyed$),
      map(([definitions, vps, mcs, options, softwares, tools]) => {
        // console.log(definitions);
        const vpsWithRules = definitions.filter(vp => vp.compatibilityRules && (vp.compatibilityRules.requires.length || vp.compatibilityRules.conflicts.length));

        const used =  mcs.concat(options, softwares, tools)

        function getDefinition(name: string) {
          const found = vpsWithRules.find(v => v.name === name);
          return found ? found.compatibilityRules : null;
        }

        const getByName = (vpName: string, vm: string) => {
          const found = vps.find(v => v.uid === `${vm}_${vpName}`);
          return found;
        }

        function findUsedByName(vpName: string, vm: string) {
          return used.find(v => v.uid === `${vm}_${vpName}`);
        }
        let errors = [];
        for (let vp of used) {
          const vm = vp.vm;
          const def = getDefinition(vp.item);
          if (!def) {
            continue;
          }
          let requireErrors = [];
          for (let req of def.requires) {
            if (!findUsedByName(req, vm)) {
              const found = getByName(req, vm);
              if (found) {
                requireErrors.push(found);
              }
            }
          }
          
          let conflictErrors = [];
          for (let con of def.conflicts) {
            const exists = findUsedByName(con, vm);
            if (exists) {
              conflictErrors.push(exists);
            }
          }
          if (conflictErrors.length || requireErrors.length) {
            errors.push({uid: vp.uid, name: vp.item, conflictErrors, requireErrors});
          }
        }
        return errors;
      })
    ).subscribe(errors => {
      this.mcHelper.updateRuleErrors(errors);
      this.optionsHelper.updateRuleErrors(errors);
      this.softwareOfflineHelper.updateRuleErrors(errors);
    });

    /*
    * prende le vps scelte
    */
    combineLatest(
      this.mcHelper.form.get('items').valueChanges
        .pipe(
          startWith([]),
          debounceTime(200)
        ),
      this.optionsHelper.form.get('items').valueChanges
        .pipe(
          startWith([]),
          debounceTime(200)
        ),
      this.softwareOfflineHelper.form.get('items').valueChanges
        .pipe(
          startWith([]),
          debounceTime(200)
        ),
      this.toolsHelper.form.get('items').valueChanges
        .pipe(
          startWith([]),
          debounceTime(200)
        ),
      this.increasesHelper.form.get('items').valueChanges
        .pipe(
          startWith([]),
          debounceTime(200)
        )
    ).pipe(
      takeUntil(this.destroyed$),
      tap(([mcs, options, softOf, tools, increases]) => {
        const vpsSelected = [...mcs, ...options, ...softOf, ...tools, ...increases];
        this.installationHelper.vpsSelected$.next(vpsSelected);
      })
    ).subscribe(_ => { });

    /*
    * carica la lista dei listini
    */
    this.listinoSrv.list()
      .subscribe(listini => {
        this.listini$.next(listini);
        this._listini = listini;
      });

    this.newQuotation$
      .pipe(
        takeUntil(this.destroyed$),
        switchMap(_ => this.listini$),
        takeUntil(this.destroyed$)
      )
      .subscribe(listini => {
        const defaulListino = listini.find(lst => lst.default);
        this.quotationForm.get('listino').patchValue(defaulListino._id, { onlySelf: true, emitEvent: true });
        this.additionalWarrantyHelper.addItems(this.additionalWarrantyHelper.staticItems);
        this.preOwnedHelper.addItems(this.preOwnedHelper.staticItems);
        this.documentsHelper.addItems(this.documentsHelper.staticItems);
        this.transportHelper.addItems(this.transportHelper.itemToInitForm);
        this.installationHelper.addItems(this.installationHelper.staticItems);
        this.extraInstallationHelper.addItems(this.extraInstallationHelper.staticItems);
      });

    /*
    * caricamento quotation --> viene sempre chiamata ma utilizzata quando carico nuova quot
    */
    combineLatest(this.loadQuotation$, this.listini$)
      .pipe(
        takeUntil(this.destroyed$),
        filter(([quotation, listini]) => {
          return quotation.listino && !listini.find(listino => listino._id === quotation.listino);
        }),
        switchMap(([quotation, listini]) => {
          return this.listinoSrv.get(quotation.listino, true)
            .pipe(map(listino => [listino, listini]));
        })
      )
      .subscribe(([listinoElim, listini]) => {
        listini.push(listinoElim);
        this.listini$.next(listini);
        this._listini = listini;
      });

    /**
     * Il price degli items nella sezione 'additional warranty' dipendono dal totalListPrice
     * di machine&options e dal tipo di service.
     * Quando almeno uno dei due cambia vado ad aggiornare il valore degli itemPrice$
     * Inizializzo i campi perché altrimenti sarebbero undefined
     */
    combineLatest(
      this.quotationForm.get('service').valueChanges.pipe(startWith('')),
      this.groups[0].listPrice$.pipe(startWith(0)), // machine e options
    ).pipe(
      tap(([service, total]) => {
        this.additionalWarrantyHelper.service_mcOptTotal$.next({ service, total });
      }),
    ).subscribe(() => { });

    // se è una nuova quotation svuoto la vm ogni volta che cambia listino
    this.quotationForm.get('listino').valueChanges
      .pipe(takeUntil(merge(this.destroyed$, this.loadQuotation$)))
      .subscribe(_ => this.quotationForm.get('vm').patchValue(undefined));

    this.selectedVM$
      .pipe(
        switchMap(vm => {
          if (vm) {
            return this.listinoSrv.getStandardVPs(vm)
              .pipe(
                map(standard => {
                  const mappedVM = this.createMachineConfigurationFromVM(vm);
                  const mappedStandard = standard.map(vp => this.createMachineConfigurationFromVP(vp));
                  return [mappedVM].concat(mappedStandard);
                })
              );
          } else {
            return of([]);
          }
        }),
        takeUntil(merge(this.destroyed$, this.loadQuotation$)),
        tap(_ => {
          const prevName = this.prevVMName ? this.prevVMName : null;
          this.mcHelper.clear(prevName);
          this.optionsHelper.clear(prevName);
          this.softwareOfflineHelper.clear(prevName);
          this.toolsHelper.clear(prevName);
          this.automationExternalHelper.clear(prevName);
          this.increasesHelper.clear(prevName);
          this.serviceMaintenanceHelper.clear();
          this.transportHelper.clear();
          this.prevVMName = this.quotationForm.get('vm').value;
        })
      )
      .subscribe(items => {
        items.forEach(item => { this.mcHelper.addItem(item); });
        if (items.length !== 0) {
          this.transportHelper.addItems(this.transportHelper.itemToInitForm);
        }
      });

    this.selectedTandemVM$
      .pipe(
        switchMap(vm => {
          if (vm) {
            return this.listinoSrv.getStandardVPs(vm)
              .pipe(
                map(standard => {
                  const mappedVM = this.createMachineConfigurationFromVM(vm);
                  const mappedStandard = standard.map(vp => this.createMachineConfigurationFromVP(vp));
                  return [mappedVM].concat(mappedStandard);
                })
              );
          } else {
            return of([]);
          }
        }),
        takeUntil(merge(this.destroyed$, this.alreadyHasTandem$)),
        tap(_ => {
          // TO-DO: fare in modo di poter eliminare solo le voci che corrispondono alle vp della seconda macchina
          const prevName = this.prevTandemVMName;
          if(prevName) {
            this.mcHelper.clear(prevName);
            this.optionsHelper.clear(prevName);
            this.softwareOfflineHelper.clear(prevName);
            this.automationExternalHelper.clear(prevName);
            this.increasesHelper.clear(prevName);
            this.toolsHelper.clear(prevName);
          }
          this.prevTandemVMName = this.quotationForm.get('tandemVM').value;
        })
      )
      .subscribe(items => {
        items.forEach(item => { this.mcHelper.addItem(item); });
      });

    combineLatest([
      this.selectedVM$.pipe(startWith(null)),
      this.selectedTandemVM$.pipe(startWith(null)),
    ])
    .pipe(takeUntil(this.destroyed$))
    .subscribe(machines => {
      let existing = machines.filter(m => !!m).map(m => m.name);
      this.machines = existing;
      this.mcHelper.setMachines(existing);
      this.optionsHelper.setMachines(existing);
      this.softwareOfflineHelper.setMachines(existing);
      this.automationExternalHelper.setMachines(existing);
      this.increasesHelper.setMachines(existing);
      this.toolsHelper.setMachines(existing);
    })

    /*
    * carica le sezioni corrispondenti, aggiungere man mano che si implementano sezioni
    */
    this.loadMachineConfiguration$
      .pipe(
        takeUntil(this.destroyed$),
        delay(50)
      )
      .subscribe(options => {
        this.mcHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.mcHelper.addItem(item));
      });

    this.loadOptions$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.optionsHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.optionsHelper.addItem(item));
      });

    this.loadSoftwareOffline$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.softwareOfflineHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.softwareOfflineHelper.addItem(item));
      });

    this.loadTools$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.toolsHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.toolsHelper.addItem(item));
      });


    this.loadAutomationExternal$.
      pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.automationExternalHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.automationExternalHelper.addItem(item));
      });

    this.loadIncreases$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.increasesHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.increasesHelper.addItem(item));
      });

    this.loadInstallation$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.installationHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.installationHelper.addItem(item));
      });

    this.loadExtraInstallation$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.extraInstallationHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.extraInstallationHelper.addItem(item));
      });

    this.loadAdditionalWarranty$.
      pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.additionalWarrantyHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.additionalWarrantyHelper.addItem(item));
      });

    this.loadServiceMaintenance$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.serviceMaintenanceHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.serviceMaintenanceHelper.addItem(item));
      });

    this.loadPreOwned$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.preOwnedHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.preOwnedHelper.addItem(item));
      });

    this.loadDocuments$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.documentsHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.documentsHelper.addItem(item));
      });

    this.loadPayments$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.paymentsHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.paymentsHelper.addItem(item));
      });

    this.loadTransport$
      .pipe(
        takeUntil(this.destroyed$),
        delay(100)
      )
      .subscribe(options => {
        this.transportHelper.form.patchValue(options);
        const items: any[] = options.items;
        items.forEach(item => this.transportHelper.addItem(item));
      });

    /*
    * parte sempre al caricamento pagina quotation
    */
    this.loadQuotation$
      .pipe(
        takeUntil(this.destroyed$),
        tap(_ => {
          this.mcHelper.clear();
          this.optionsHelper.clear();
          this.softwareOfflineHelper.clear();
          this.toolsHelper.clear();
          this.automationExternalHelper.clear();
          this.increasesHelper.clear();
          this.installationHelper.clear();
          this.extraInstallationHelper.clear();
          this.additionalWarrantyHelper.clear();
          this.serviceMaintenanceHelper.clear();
          this.preOwnedHelper.clear();
          this.documentsHelper.clear();
          this.paymentsHelper.clear();
          this.transportHelper.clear();
        })
      )
      .subscribe(quotation => {
        this.prevCustomPrice = quotation.customPrice;
        this.prevPrice = quotation.price;
        this.quotationForm.patchValue(quotation);
        if (quotation.notes) {
          const notesArr = this.quotationForm.get('notes') as FormArray;
          quotation.notes.forEach(note => {
            notesArr.push(new FormControl(note));
          });
        }

        if (quotation.notesExternal) {
          const notesArr = this.quotationForm.get('notesExternal') as FormArray;
          quotation.notesExternal.forEach(note => {
            notesArr.push(new FormControl(note));
          });
        }
        this.quotationId = quotation._id;
        if (quotation._id) {
          this.quotationForm.get('vm').disable();
        }
        if (quotation.hasTandem) {
          this.quotationForm.get('tandemVM').disable();
        }
        if (quotation.opportunity) {
          this.opportunity$.next(quotation.opportunity);
        }
      });

    this.mcHelper.items$
      .pipe(
        takeUntil(this.destroyed$),
        filter(items => !!items.length)
      )
      .subscribe(items => {
        this.numOfMachines = items[0].qty;
      });

    this.discount$ = combineLatest(
      this.totalQuotation$,
      this.totalPriceMachineOpts$,
      this.quotationForm.get('customPrice').valueChanges.pipe(startWith(this.quotationForm.get('customPrice').value))
    ).pipe(
      map(([price, moPrice, customPrice]) => {
        if (!customPrice) {
          return undefined;
        }
        const delta = price - moPrice;

        const discount = (1 - (customPrice - delta) / moPrice) * 100;
        return discount * -1;
      })
    );
  }

  addNote(noteField) {
    this.quotationForm.get('notes').value.splice(0, 0, noteField.value);
    noteField.value = '';
  }

  addNoteExternal(noteField) {
    this.quotationForm.get('notesExternal').value.splice(0, 0, noteField.value);
    noteField.value = '';
  }

  removeNote(index) {
    this.quotationForm.get('notes').value.splice(index, 1);
  }

  removeNoteExternal(index) {
    this.quotationForm.get('notesExternal').value.splice(index, 1);
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroySections();
    this.destroyed$.complete();
  }

  protected destroySections() {
    for (const g of this.groups) {
      for (const s of g.sections) {
        s.destroy();
      }
    }
  }

  save(isPriceListChanging?: boolean) {
    if (this.quotationForm.invalid) {
      this.scrollToTop();
      return;
    }

    const value = this.quotationForm.value;

    const data = {
      ...value,
      sections: [
        value.machineConfiguration,
        value.options,
        value.softwareOffline,
        value.tools,
        value.automationExternal,
        value.increases,
        value.installation,
        value.extraInstallation,
        value.additionalWarranty,
        value.serviceMaintenance,
        value.preOwned,
        value.documents,
        value.payments,
        value.transport
      ],
    };
    delete data.machineConfiguration;
    delete data.options;
    delete data.softwareOffline;
    delete data.tools;
    delete data.automationExternal;
    delete data.increases;
    delete data.installation;
    delete data.extraInstallation;
    delete data.additionalWarranty;
    delete data.serviceMaintenance;
    delete data.preOwned;
    delete data.documents;
    delete data.payments;
    delete data.transport;
    delete data.onlyNet;

    const result = new Subject<any>();
    combineLatest([this.aclState$, this.isAm$])
      .pipe(
        takeUntil(this.destroyed$),
        filter(([aclState, _]) => aclState.isAuthenticated())
      )
      .subscribe(([aclState, isAm]) => {
        if (isAm) {
          this.quotationId
            ? (aclState.checkId(this.quotation.createdBy._id) ? this.openRevisionDialog(this.quotationId, data) : this.openInternalCopyDialog(this.quotationId, data))
            : this.openRevisionDialog('', {...data, isInternal: true});
        } else {
          if (isPriceListChanging) {
            this.quotationSrv.update(this.quotationId, data)
              .subscribe(_ => result.next());
          } else {
            const tmpRes = this.quotationId
              ? this.openRevisionDialog(this.quotationId, data)
              : this.openRevisionDialog('', data);
            tmpRes.subscribe(_ => result.next());
          }
        }
      });
    return result;
  }

  openInternalCopyDialog(quotationId: string, quotationData: any) {
    const revisionResult = new Subject<any>();
    this.dialog.open(InternalConfirmationDialogComponent)
    .afterClosed()
    .subscribe(result => {
      if (result) {
        this.quotationSrv.createVersion(quotationId, {...quotationData, internalName: result, isInternal: true})
          .subscribe(result => {
            revisionResult.next();
            this.router.navigate(['/quotations', result._id]);
          })
      }
    });
    return revisionResult;
  }

  openRevisionDialog(quotationId: string, quotationData: any) {

    let askPriceConfirmation = false;
    if (this.prevPrice && this.prevPrice !== quotationData.price) {
      if (this.prevCustomPrice === quotationData.customPrice) {
        askPriceConfirmation = true;
      }
    }

    const dialogRef = this.dialog.open(RevisionDialogComponent, {
      width: '500px',
      data: {
        quotationId,
        quotationData,
        showPriceAlert: askPriceConfirmation,
        prevPrice: this.prevPrice,
        currentPrice: quotationData.price
      }
    });

    const revisionResult = new Subject<any>();
    dialogRef.afterClosed()
      .subscribe(result => {
        if (!result) { return; } // close without save
        const quotId = result.quotationId;
        const newQuotData = result.quotationData;
        const spinnerDialog = this.quotation.revision !== result.revision ? this.dialog.open(SavingDialogComponent) : null;
        if (quotId) {
          this.quotationSrv.update(quotId, newQuotData)
            .subscribe(() => {
              // this.router.navigate(['quotations']);
              if (spinnerDialog) {
                spinnerDialog.close();
              }
              revisionResult.next();
              window.location.reload();
            });
        } else {
          this.quotationSrv.add(newQuotData)
            .subscribe((res) => {
              if (spinnerDialog) {
                spinnerDialog.close();
              }
              revisionResult.next();
              this.router.navigate(['quotations', res._id]);
            });
        }
      });

    return revisionResult;
  }

  openReplaceListinoDialog() {
    // cerco qui perché l'API del dialog non prende anche i listini eliminati
    const listinoSelected = this._listini.find((el) => el._id === this.quotationForm.get('listino').value);
    const dialogRef = this.dialog.open(ListinoDialogComponent, {
      width: '400px',
      data: {
        selectedListino: listinoSelected,
      }
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        // salva la quotation
        const isPriceListChanging = true;

        this.save(isPriceListChanging)
          .pipe(switchMap(_ => this.quotationSrv.updateListino(this.quotationId, result.idListino)))
          .subscribe(
            () => window.location.reload(),
            err => this.snackBar.open('Price list not compatibe with current quotation!', null, { duration: 3 * 1000 })
          );
      }
    });
  }

  openReplaceVMDialog() {
    // cerco qui perché l'API del dialog non prende anche i listini eliminati
    const listino = this.quotationForm.get('listino').value;
    const dialogRef = this.dialog.open(VMDialogComponent, {
      width: '400px',
      data: {
        listino,
      }
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        const isPriceListChanging = true;
        this.save(isPriceListChanging)
          .pipe(switchMap(_ => this.quotationSrv.updateVM(this.quotationId, result.vm)))
          .subscribe(
            () => window.location.reload(),
            err => this.snackBar.open('Error changing VM!', null, { duration: 3 * 1000 })
          );
      }
    });
  }

  openReplaceTandemVMDialog() {
    // cerco qui perché l'API del dialog non prende anche i listini eliminati
    const listino = this.quotationForm.get('listino').value;
    const dialogRef = this.dialog.open(VMDialogComponent, {
      width: '400px',
      data: {
        listino,
      }
    });

    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        const isPriceListChanging = true;
        this.save(isPriceListChanging)
          .pipe(switchMap(_ => this.quotationSrv.updateTandemVM(this.quotationId, result.vm)))
          .subscribe(
            () => window.location.reload(),
            err => this.snackBar.open('Error changing VM!', null, { duration: 3 * 1000 })
          );
      }
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  setCustomDiscount(helper: VpSectionHelper, item: VpItem) {
    helper.setCustomDiscount(item);
  }

  removeCustomDiscount(helper: VpSectionHelper, item: VpItem) {
    helper.removeCustomDiscount(item);
  }

  trackByItem(index, item) {
    return `${item.item}_${index}`;
  }

  showRemoveDiscount = (item: { helper: VpSectionHelper, item: VpItem }) => {
    if (item.helper instanceof PreOwnedHelper) {
      return false;
    }
    if (item.helper instanceof DocumentsHelper) {
      return false;
    }
    if (item.helper instanceof TransportHelper) {
      return false;
    }
    if (item.helper instanceof PaymentsHelper) {
      return false;
    }
    return item.helper.hasCustomDiscount(item.item);
  }

  showAddDiscount = (item: { helper: VpSectionHelper, item: VpItem }) => {
    if (item.helper instanceof PreOwnedHelper) {
      return false;
    }
    if (item.helper instanceof DocumentsHelper) {
      return false;
    }
    if (item.helper instanceof TransportHelper) {
      return false;
    }
    if (item.helper instanceof PaymentsHelper) {
      return false;
    }
    return !item.item.standard && !item.helper.hasCustomDiscount(item.item);
  }

  protected createMachineConfigurationFromVM(vm: any, discount1 = 0, discount2 = 0, discount3 = 0): any {
    return {
      item: vm.name,
      description: vm.modello,
      note: '',
      unitPrice: vm.price,
      qty: 1,
      kind: 'vm'
    };
  }

  protected createMachineConfigurationFromVP(vp: any, discount1 = 0, discount2 = 0, discount3 = 0): any {
    return {
      item: vp.vp,
      uid: vp.uid,
      vm: vp.vm,
      description: vp.description,
      note: vp.note,
      unitPrice: vp.specs.price,
      qty: 1,
      kind: 'vp',
      toCheck: vp.specs.toCheck,
      toBeDefined: vp.specs.toBeDefined,
      standard: vp.specs.standard
    };
  }

  protected clearArraySection(section: string) {
    const formArray = this.quotationForm.get(section).get('items') as FormArray;
    this.quotationForm.get('totalPrice').patchValue(0);
    formArray.clear();
  }

  protected getFormValue(formGroup: FormGroup, property: string) {
    return formGroup.get(property).value;
  }

  protected getFormElement(formGroup: FormGroup, property: string) {
    return formGroup.get(property);
  }

  /**
   * Utile quando il form della quotation è invalido e si prova a salvare...
   * Ciò permette all'utente che non si trova in cima alla pagina di vedere
   * gli errori della form
   */
  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  statusChange(status, element) {
    this.quotationSrv.update(element._id, { status }).subscribe();
    element.status = status;
  }

  changeOnlyNet(value) {
    this.quotation.onlyNet = value;
    this.quotationSrv.update(this.quotation._id, { onlyNet: value }).subscribe();
  }

  changeCustomPrice(value) {
    this.quotation.customPrice = value;
    this.quotationForm.get('customPrice').patchValue(value);
  }

  setOrderConfirmation() {
    const dialogRef = this.dialog.open(ConfOrdineDialogComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const { matricola, expectedDelivery } = result;
        const status = 'approved';
        const data: any = {
          matricola,
          status
        };
        if (expectedDelivery) {
          data.expectedDelivery = expectedDelivery
        }
        this.quotationSrv.update(this.quotation._id, data)
          .subscribe(res => {
            this.quotation.status = status,
            this.quotation.matricola = matricola;
            this.quotation.expectedDelivery = expectedDelivery;
            this.quotation.orderFiles = res.orderFiles;
            this.quotationForm.patchValue({ status, matricola });
          });
      }
    });

  }

  tandem(qty: number) {
    const dialogRef = this.dialog.open(TandemDialogComponent, {
      width: '400px',
      data: {
        qty: this.numOfMachines
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.machineOptSections.forEach(sectionHelper => {
          sectionHelper.multiplyQty(this.numOfMachines, result.qty);
        });
      }
    });
  }

  showTandem = (item: { item: VpItem }) => {
    return item.item.kind === 'vm';
  }

  hasRevision() {
    return this.quotation && !isNil(this.quotation.revision);
  }

  drop(event) {
    const { previousIndex, currentIndex, previousContainer, container, item} = event;
    if (container.data === previousContainer.data) {
      container.data.moveItem(previousIndex, currentIndex);
    } else {
      previousContainer.data.removeItem(item.data);
      container.data.addItemAt(item.data, currentIndex);
    }
  }

  showVersions() {
    this.dialog.open(VersionsDialogComponent, {
      width: '400px',
      data: this.quotationId
    });
  }

  getPictureUrl(item: any) {
    return `/api/machine-pictures/${item._id}/picture?hash=${(new Date(item.updatedAt)).getTime()}`;
  }

  addTandemMachine() {
    this.quotationForm.get('hasTandem').patchValue(true);
  }



  resolveConflictDialog(section, errors) {
    this.dialog.open(ConflictDialogComponent, {
      width: '400px',
      data: errors
    })
      .afterClosed()
      .subscribe(({requireErrors, conflictErrors}) => {
        for (let toAdd of requireErrors) {
          section.addItem(toAdd);
        }
        for (let toRemove of conflictErrors) {
          this.mcHelper.removeItem(toRemove.uid);
          this.optionsHelper.removeItem(toRemove.uid);
          this.softwareOfflineHelper.removeItem(toRemove.uid);
          this.toolsHelper.removeItem(toRemove.uid);
        }
      });
  }
}
