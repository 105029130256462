// tslint:disable: variable-name
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { Section } from '../machineopt-increases/section-base';
import { InstallationItem } from './installation-section';

export class ExtraInstallationSectionHelper extends Section<InstallationItem> {
  public hasOnlyOneDiscount = true;
  staticItems: Partial<InstallationItem>[] =
    [
      {
        item: 'StartUp & Training',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'set',
        hasDaysDisabled: false,
        hasTechDisabled: false
      },
      {
        item: 'Number of engineers from local partner',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'nelp',
        hasDaysDisabled: true,
        hasTechDisabled: false
      },
      {
        item: 'Traveling',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'trav',
        hasDaysDisabled: true,
        hasTechDisabled: true,
        travKm: 0,
        travN: 0
      },
      {
        item: 'Traveling Days',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'travdays',
        hasDaysDisabled: false,
        hasTechDisabled: false
      },
      {
        item: 'Board and lodging',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'bal',
        hasDaysDisabled: false,
        hasTechDisabled: false
      },
      {
        item: 'Daily Allowance',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'da',
        hasDaysDisabled: false,
        hasTechDisabled: false
      },
      {
        item: 'Car Rental (80,00 € per day)',
        days: 1,
        price: 0,
        tech: 1,
        unitPrice: 0,
        listPrice: 0,
        name: 'cr',
        hasDaysDisabled: true,
        hasTechDisabled: true
      },
      {
        item: 'Transfer (100,00 € each trip)',
        days: 1,
        price: 0,
        tech: 1,
        unitPrice: 0,
        listPrice: 0,
        name: 'trans',
        hasDaysDisabled: true,
        hasTechDisabled: true
      },
      {
        item: 'Working hours weekend',
        days: 0,
        price: 0,
        tech: 0,
        unitPrice: 0,
        listPrice: 0,
        name: 'whw',
        hasDaysDisabled: false,
        hasTechDisabled: false
      },
      {
        item: 'Flat Rate',
        days: 1,
        price: 0,
        tech: 1,
        unitPrice: 0,
        listPrice: 0,
        name: 'flr',
        hasDaysDisabled: true,
        hasTechDisabled: true
      }
    ];

  showWarning = false;

  hasDiscount$ = this.items$
    .pipe(
      map(items => {
        for (const item of items) {
          if (this.hasCustomDiscount(item)) {
            return true;
          }
        }
        return false;
      }),
    );

  constructor(title: string, public name: string) {
    super(title, name);
    this.registerRecalculateItems();
    this.hasDiscount$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => { this.updateColumns$.next(); });
  }

  registerRecalculateItems() {
    this.form.get('discount1').valueChanges.pipe(startWith(0))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(this.recalculateItems$);
  }

  protected itemToFormGroup(item: InstallationItem): FormGroup {

    const fg = new FormGroup({
      item: new FormControl(item.item),
      days: new FormControl(item.days),
      tech: new FormControl(item.tech),
      unitPrice: new FormControl(item.unitPrice),
      listPrice: new FormControl(item.listPrice),
      price: new FormControl(item.price),
      name: new FormControl(item.name),
      hasDaysDisabled: new FormControl(item.hasDaysDisabled),
      hasTechDisabled: new FormControl(item.hasTechDisabled)
    });

    if (item.name === 'trav') {
      fg.removeControl('days');
      fg.removeControl('tech');
      fg.addControl('travKm', new FormControl(item.travKm));
      fg.addControl('travN', new FormControl(item.travN));
    }

    if (item.discount1 !== undefined) {
      fg.addControl('discount1', this.createDiscountForm(item.discount1));
    }

    return fg;
  }

  protected createForm() {
    const basicForm = new FormGroup({
      name: new FormControl(),
      machineDestination: new FormControl('it'),
      items: new FormArray([]),
      price: new FormControl(0),
      discount1: new FormControl(0),
      listPrice: new FormControl(0),
      bopr: new FormControl(false)
    });
    return basicForm;
  }

  protected calculateItem(item: InstallationItem, index): InstallationItem {
    // set
    // nelp
    // trav
    // travdays
    // bal
    // da
    // cr
    // trans
    // whw

    const secDiscount = this.form.get('discount1').value;
    const hasDiscount = this.hasCustomDiscount(item);
    const discount1 = hasDiscount ? item.discount1 : secDiscount;

    let listPrice = 0;

    if (item.name === 'trav') {
      const travKm = this.getItemControl(index, 'travKm').value;
      const travQty = this.getItemControl(index, 'travN').value;
      item.unitPrice = travKm * travQty;
      this.getItemControl(index, 'unitPrice').patchValue(item.unitPrice, { emitEvent: false });
    } else {
      listPrice = item.days * item.tech * item.unitPrice;
    }
    item.listPrice = listPrice;
    item.price = listPrice * (1 - discount1 / 100);

    this.getItemControl(index, 'listPrice').patchValue(item.listPrice, { emitEvent: false });
    this.getItemControl(index, 'price').patchValue(item.price, { emitEvent: false });
    return item;
  }

  hasCustomDiscount(item: InstallationItem) {
    return item.discount1 !== undefined;
  }

  protected calcColumns(): Observable<string[]> {
    for (const item of this._items) {
      if (this.hasCustomDiscount(item)) {
        return of(
          [
            'autext_description',
            'inst_days',
            'inst_tech',
            'inst_unitPrice',
            'listPrice',
            'singlecs_discount',
            'price',
          ]
        );
      }
    }
    return of(
      [
        'autext_description',
        'inst_days',
        'inst_tech',
        'inst_unitPrice',
        'listPrice',
        'price',
      ]
    );
  }

  protected createDiscountForm(discount) {
    return new FormControl(discount, [Validators.min(0), Validators.max(100)]);
  }

  setCustomDiscount(item: InstallationItem) {
    if (!this.hasCustomDiscount(item)) {
      const index = this._items.indexOf(item);
      if (index !== -1) {
        const formArr = this.form.get('items') as FormArray;
        const itemGr = formArr.at(index) as FormGroup;
        itemGr.addControl('discount1', this.createDiscountForm(0));
      }
    }
  }

  removeCustomDiscount(item: InstallationItem) {
    if (this.hasCustomDiscount(item)) {
      const index = this._items.indexOf(item);
      if (index !== -1) {
        const formArr = this.form.get('items') as FormArray;
        const itemGr = formArr.at(index) as FormGroup;
        itemGr.removeControl('discount1');
      }
    }
  }

  setUnitPriceStep(elementName) {
    let step = 1;
    if (elementName === 'cr') {
      step = 80;
    }
    if (elementName === 'trans') {
      step = 100;
    }
    return step;
  }

  resetFields() {
    this.form.reset();
  }

  footerRow(): string[] {
    return ['autext_description'];
  }

  /**
   * Aggiungo tandem2machine ai tech e day di startup&training
   * se la qty della vm selezionata è >1
   */
  addTandem(vpsSelected: any[]) {
  }


  /**
   * Aggiunge il valore richiesto se almeno una delle vps selezionate
   * corrisponde a quella di sf_d, gpsa_d, gpsb_d, ...
   */
  addProperties(vpsSel) {

  }

}
