import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OpportunityService {

  constructor(protected http: HttpClient) { }

  getOpportunity(id: string): Observable<any> {
    /*
      l'inmemorydb contiene un array di un solo oggetto
      in modo tale da fare la query per id... quando avremo le api sarà diverso.
      mi torna quindi un [object] e torno solo il primo oggetto
    */
    return this.http.get(`/opportunities/${id}`);
    //    return this.http.get(`/opportunity/{id}`, options); QUESTA SARà L'EFFETTIVA CHIAMATA
  }
}
