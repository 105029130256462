import { Observable, of, Subject, combineLatest } from 'rxjs';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { SectionItem, Section } from '../machineopt-increases/section-base';

export interface PreOwnedItem extends SectionItem {
    item: string;
    unitPrice: number;
}

export class PreOwnedHelper extends Section<PreOwnedItem> {

    staticItems: Partial<PreOwnedItem>[] =
        [
            {
                item: 'Take in Used Gasparini',
                unitPrice: 0
            },
            {
                item: 'Transport cost',
                unitPrice: 0
            },
            {
                item: 'Take in Used Machine',
                unitPrice: 0
            },
        ];

    price$ = of(0);
    sectionPrice$ = this.items$.pipe(
        map(items => items.map(item => item.unitPrice)),
        map(prices => {
            return prices.reduce((prev, current) => {
                return prev + current;
            }, 0);
        }),
        startWith(0)
    );
    listPrice$ = this.items$.pipe(
        map(items => items.map(item => item.unitPrice)),
        map(prices => {
            return prices.reduce((prev, current) => {
                return prev + current;
            }, 0);
        }),
        startWith(0)
    );
    constructor(title: string, public name: string) {
        super(title, name);
        this.form.patchValue({ name });
        this.sectionPrice$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(price => {
                this.form.get('price').patchValue(price, { onlySelf: true });
            });

    }

    protected itemToFormGroup(item: PreOwnedItem): FormGroup {
        const fg = new FormGroup({
            item: new FormControl(item.item),
            unitPrice: new FormControl(item.unitPrice),
        });
        return fg;
    }


    protected createForm() {
        const basicForm = new FormGroup({
            name: new FormControl(),
            items: new FormArray([]),
            listPrice: new FormControl(0),
            price: new FormControl(0),
        });
        return basicForm;
    }

    protected calcColumns(): Observable<string[]> {
        return of(
            [
                'autext_description',
                'preowned_price',
            ]
        );
    }

    footerRow(): string[] {
        return [];
    }
}
