import { Subject, combineLatest, Observable, of, BehaviorSubject } from 'rxjs';
import { startWith, switchMap, map, filter } from 'rxjs/operators';
import { FormArray, FormGroup, FormControl, Validators, ValidatorFn } from '@angular/forms';
import { isString } from 'lodash';
import { Section, SectionItem } from '../machineopt-increases/section-base';

export interface PaymentItem extends SectionItem {
  rate?: number;
  hasOnlyNote?: boolean;
}

export class PaymentsHelper extends Section<PaymentItem> {
  constructor(title: string, public name: string) {
    super(title, name);
  }

  get showAddNew(): boolean {
    return this._showAddNew;
  }

  title: string;

  protected updateColumns$ = new Subject<void>();

  columns$ = this.updateColumns$
    .pipe(
      startWith(() => { }),
      switchMap(() => this.calcColumns())
    );

  protected recalculateItems$ = new Subject<void>();
  addControl = new FormControl();

  staticItems: BehaviorSubject<Partial<PaymentItem>[]> = new BehaviorSubject(
    [
      {
        item: 'To be agreed',
        rate: 0,
        unitPrice: 0,
        note: '',
      },
      {
        item: 'Leasing',
        rate: 0,
        unitPrice: 0,
        note: '',
      },
      {
        item: 'Down payment  with the order against bank warranty from Gasparini srl.',
        rate: 0,
        unitPrice: 0,
        note: '',
      }, {
        item: 'Down payment  with the order.',
        rate: 0,
        unitPrice: 0,
        note: '',
      },
      {
        item: 'Before Shipment. The payment should be arrived on our account 1 week before shipment date.',
        rate: 0,
        unitPrice: 0,
        note: '',
      },
      {
        // tslint:disable-next-line: max-line-length
        item: 'Against our notification on "ready to dispatch" (after 1 week from machine\'s availability an amount of euro 500,00 per week will be charged)',
        rate: 0,
        unitPrice: 0,
        note: '',
      },
      {
        // tslint:disable-next-line: max-line-length
        item: 'Letter of Credit (within 3 weeks from the order confirmation) Against irrevocable L/C in English language, available on an Italian bank. L/C expires 21 days after presentation of the negotiation documents. Payable as follows:',
        rate: 0,
        unitPrice: 0,
        note: '',
      },
      {
        item: 'Payable at sight',
        rate: 0,
        unitPrice: 0,
        note: '',
      },
      {
        item: 'Payable against shipping documents',
        rate: 0,
        unitPrice: 0,
        note: '',
      },
      {
        item: 'Payable After Installation and commissioning against Acceptance certificate  Maximum 60 days from shipment date.',
        rate: 0,
        unitPrice: 0,
        note: '',
      },
      {
        item: 'Payable After Installation and commissioning against Acceptance certificate  Maximum 15 days from shipment date.',
        rate: 0,
        unitPrice: 0,
        note: '',
      },
      {
        // tslint:disable-next-line: max-line-length
        item: 'After installation (against signed installation report). The payment should be arrived on our account 1 week after installation date.',
        rate: 0,
        unitPrice: 0,
        note: '',
      },

      {
        // tslint:disable-next-line: max-line-length
        item: 'Rest coverd by a Irrevocable Bank guarantee to GASPARINI Industries srl. Not later than 20 days after receiving our order confirmation and accepted by our bank.',
        rate: 0,
        unitPrice: 0,
        note: '',
        hasOnlyNote: true
      },
      {
        // tslint:disable-next-line: max-line-length
        item: 'Remark: the bank\'s expenses connected to the wire transfer are at buyer\'s charge\'',
        rate: 0,
        unitPrice: 0,
        note: '',
        // hasOnlyNote: true
      },
    ]);

  // tslint:disable-next-line:variable-name
  protected _showAddNew = false;

  customItemForm = new FormGroup({
    item: new FormControl(),
    rate: new FormControl(),
    unitPrice: new FormControl(),
    note: new FormControl(),
    checked: new FormControl(),
  });

  addOptions$ = combineLatest(
    this.staticItems,
    this.addControl.valueChanges.pipe(startWith(''))
  ).pipe(
    map(([vps, formValue]) => [vps, formValue ? formValue : '']),
    filter(([_, formValue]) => isString(formValue)),
    map(([vps, formValue]) => {
      formValue = formValue.toLowerCase();

      if (!formValue) {
        return vps;
      }
      return vps.filter(vp => {
        const itemName: string = vp.item.toLowerCase();
        return itemName.includes(formValue);
      });
    }),
    map(vps => {
      return [{ item: 'Manual item', kind: 'add_new' }].concat(vps);
    })
  );

  rateOver: boolean;

  toggleAddNew() {
    this._showAddNew = !this._showAddNew;
    this.customItemForm.reset();
  }

  addItem(item: PaymentItem | Partial<PaymentItem>) {
    this.addControl.patchValue('');
    const currentVps = this.staticItems.value;
    if (item.kind === 'add_new') {
      this.toggleAddNew();
      return;
    }
    const itemInListIdx = currentVps.indexOf(currentVps.find(vp => vp.item === item.item));
    currentVps.splice(itemInListIdx, 1);
    const formItems = this.form.get('items') as FormArray;
    formItems.push(this.itemToFormGroup(item));
  }

  protected itemToFormGroup(item: PaymentItem | Partial<PaymentItem>): FormGroup {
    const fg = new FormGroup({
      item: new FormControl(item.item),
      rate: new FormControl(item.rate),
      unitPrice: new FormControl(item.unitPrice),
      note: new FormControl(item.note),
      hasOnlyNote: new FormControl(item.hasOnlyNote),
    });

    return fg;
  }

  protected createForm() {
    const basicForm = new FormGroup({
      name: new FormControl(),
      items: new FormArray([], this.checkRateFormValidation()),
      price: new FormControl(0),
      listPrice: new FormControl(0),
    });
    return basicForm;
  }

  checkRateFormValidation(): ValidatorFn {
    return (formItems: FormArray): any | null => {
      const sumRate = formItems.value.reduce((acc, curr) => curr.rate + acc, 0);
      if (sumRate > 100) {
        this.rateOver = true;
        return {};
      } else {
        this.rateOver = false;
        return null;
      }
    };
  }

  addNewItem() {
    if (this.customItemForm.invalid) {
      return;
    }
    const item: Partial<PaymentItem> = {
      item: '',
      rate: 0,
      unitPrice: 0,
      note: '',
      hasOnlyNote: false
    };

    item.item = this.customItemForm.get('item').value;
    this.toggleAddNew();
    this.addItem(item);
    this.customItemForm.reset();
  }

  protected calcColumns(): Observable<string[]> {
    return of(
      [
        'payments_description',
        'note',
        'addwa_rate',
        'unitPrice',
        'actions',
      ]
    );
  }

  footerRow(): string[] {
    return ['payments_description', 'note'];
  }
}
