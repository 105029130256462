import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MatSelectionList, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-conflict-dialog',
  templateUrl: './conflict-dialog.component.html'
})
export class ConflictDialogComponent implements AfterViewInit {
  @ViewChild('requireList', {static: true}) requireList!: MatSelectionList;
  @ViewChild('conflictList', {static: true}) conflictList!: MatSelectionList;

  requireErrors: any[];
  conflictErrors: any[];

  constructor(
    public dialogRef: MatDialogRef<ConflictDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public errorsData: any) {
  }

  ngAfterViewInit(): void {
    this.requireErrors = this.errorsData.requireErrors;
    this.conflictErrors = this.errorsData.conflictErrors;
    setTimeout(() => {
      this.requireList.selectAll();
      this.conflictList.selectAll();
    }, 10);
  }

  requireSelectionChanged(event) {
    this.requireErrors = this.requireList.selectedOptions.selected.map(o => o.value);
  }

  conflictSelectionChanged(event) {
    this.conflictErrors = this.conflictList.selectedOptions.selected.map(o => o.value);
  }

  save() {
    this.dialogRef.close({requireErrors: this.requireErrors, conflictErrors: this.conflictErrors});
  }

  closeWithoutSave() {
    this.dialogRef.close({requireErrors: [], conflictErrors: []});
  }
}
