import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirm-delete-dialog',
  template: `
    <h2 mat-dialog-title>Delete quotation!</h2>
    <h4>Are you sure to delete this quotation?</h4>
    <div *ngIf="data.project">Project: {{ data.project }}</div>
    <div *ngIf="data.customer">Customer: {{ data.customer }}</div>
    <div *ngIf="data.customer">Description: {{ data.descripton }}</div>
    <div *ngIf="data.createdBy">Created By: {{ data.createdBy }}</div>

    <div mat-dialog-actions style="justify-content: flex-end;">
      <button mat-button mat-dialog-close type="button">No, go back</button>
      <button mat-raised-button color="warn" type="button" cdkFocusInitial [mat-dialog-close]="true">
        DELETE
      </button>
    </div>
  `,
  styleUrls: []
})
export class ConfirmDeleteDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      project: any;
      customer: any;
      descripton: any;
      createdBy: any;
    }) { }
}
