import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { UsersRoutingModule } from './users-routing.module';
import { UserEditComponent, ResetPasswordComponent } from './edit/edit.component';
import { UserService } from './users.service';
import { UserListComponent } from './list/list.component';
import { MeComponent } from './me/me.component';
import { AddUserComponent } from './add/add.component';

@NgModule({
  imports: [
    SharedModule,
    UsersRoutingModule
  ],
  providers: [UserService],
  declarations: [
    UserEditComponent,
    UserListComponent,
    ResetPasswordComponent,
    MeComponent,
    AddUserComponent],
  entryComponents: [ResetPasswordComponent]
})
export class UsersModule { }
