import { VmPictureService } from './../../_services/vm-picture.service';
import { MatDialog } from '@angular/material';
import { Component, OnInit } from "@angular/core";
import { assign } from 'lodash';
import { Subject } from 'rxjs';
import { VMEditDialogComponent } from './vm-edit-dialog/vm-edit-dialog.component';

@Component({
  selector: 'app-vm-pictures-list',
  templateUrl: './vm-pictures.component.html'
})
export class VmPicturesListComponent implements OnInit {
  list: any[] = [];
  list$ = new Subject<any[]>();

  constructor(protected srv: VmPictureService,
              protected dialog: MatDialog) {}

  ngOnInit(): void {
    this.srv.list()
      .subscribe(data => {
        this.list = data;
        this.refreshList();
      });
  }

  protected refreshList() {
    this.list$.next(this.list);
  }

  protected updateItem(original, updated) {
    assign(original, updated);
    this.refreshList();
  }

  edit(vp: any) {
    this.dialog.open(VMEditDialogComponent, {data: vp})
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.srv.update(vp._id, data.name, data.picture)
            .subscribe(updated => {
              this.updateItem(vp, updated);
            });
        }
      });
  }

  add() {
    this.dialog.open(VMEditDialogComponent)
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.srv.create(data.name, data.picture)
            .subscribe(created => {
              this.list.splice(0, 0, created);
              this.refreshList();
            });
        }
      });
  }

  getPictureUrl(item: any) {
    return `/api/machine-pictures/${item._id}/picture?hash=${(new Date(item.updatedAt)).getTime()}`;
  }
}
