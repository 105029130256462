import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(protected http: HttpClient) { }

  list(): Observable<any[]> {
    return this.http.get<any[]>('/translations');
  }

  import(formData): Observable<any[]> {
    return this.http.post<any[]>('/translations/import', formData);
  }
}
