import { Observable, of, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { FormArray, FormGroup, FormControl } from '@angular/forms';
import { Section, SectionItem } from '../machineopt-increases/section-base';

export interface DocumentItem extends SectionItem {
    checked: boolean;
}

export class DocumentsHelper extends Section<DocumentItem> {

    staticItems: Partial<DocumentItem>[] =
        [
            {
                item: 'CE Declaration of Conformity',
                checked: false,
                note: 'Not included'
            },
            {
                item: 'Operation Manual in electronic format',
                checked: false,
                note: 'Not included'
            },
            {
                item: 'Operation Manual on hardcopy only when demanded',
                checked: false,
                note: 'Not included'
            },
            {
                item: 'Certificate of Origin (only if needed)',
                checked: false,
                note: 'Not included'
            },
            {
                item: 'Packing list with the “ready for shipment” notification.',
                checked: false,
                note: 'Not included'
            },
        ];

    constructor(title: string, public name: string) {
        super(title, name);
        this.recalculateItems$.next();
    }

    protected itemToFormGroup(item: DocumentItem): FormGroup {
        const fg = new FormGroup({
            item: new FormControl(item.item),
            checked: new FormControl(item.checked),
            note: new FormControl(item.note),
        });

        return fg;
    }


    protected createForm() {
        const basicForm = new FormGroup({
            name: new FormControl(),
            items: new FormArray([]),
            price: new FormControl(0),
            listPrice: new FormControl(0),
        });
        return basicForm;
    }

    protected calculateItem(item: DocumentItem, index): DocumentItem {
        if (!item.checked) {
            item.note = 'Not included';
        }
        if (item.checked) {
            item.note = 'Included';
        }
        this.getItemControl(index, 'note').patchValue(item.note, {emitEvent: false});
        return item;
    }

    protected calcColumns(): Observable<string[]> {
        return of(
            [
                'autext_description',
                'documents_choice',
                'note',
            ]
        );
    }

    footerRow(): string[] {
        return [];
    }
}
