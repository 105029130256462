import { Component } from '@angular/core';
import { UserService } from './../users.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class UserListComponent {
  users$ = this.srv.find();

  constructor(protected srv: UserService) { }

}
