import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as urljoin from 'url-join';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class UserService {
  constructor(protected http: HttpClient) {}

  find(query?): Observable<any[]> {
    return this.http.get<any[]>(`users`, { params: query });
  }

  get(id: string): Observable<any> {
    return this.http.get(`users/${id}`);
  }

  me() {
    return this.http.get(`users/me`);
  }

  update(id: string, data: any): Observable<any> {
    return this.http.put(`users/${id}`, data);
  }

  create(data: any): Observable<any> {
    return this.http.post(`users`, data);
  }

  addRole(id: string, role: string) {
    return this.http.post(`users/${id}/roles/${role}`, {});
  }

  removeRole(id: string, role: string) {
    return this.http.delete(`users/${id}/roles/${role}`);
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.http.put('auth/local/password', { oldPassword, newPassword });
  }

  resetPassword(userId: string): Observable<string> {
    return this.http.post(`users/${userId}/password`, {})
      .pipe(
        map((res: any) => res.newPassword)
      );
  }
}
