import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

const allowedContentTypes = ['application/json', 'multipart/form-data', undefined];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private authService: AuthService;
  constructor(private injector: Injector) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.authService = this.injector.get(AuthService);
    const token: string = this.authService.getToken();
    const originalContentType = request.headers.get('Content-Type');
    const removeContentType = originalContentType === 'remove';
    request = request.clone({
      setHeaders: {
        // tslint:disable-next-line:object-literal-key-quotes
        'Authorization': `Bearer ${token}`
      }
    });

    if (removeContentType) {
      request.headers.delete('Content-Type');
    }
    return next.handle(request);
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError((response: any) => {
          if (response instanceof HttpErrorResponse && (response.status === 401 || response.status === 403)) {
            localStorage.removeItem('token');
            this.router.navigateByUrl('/login');
          }
          return throwError(response);
        })
      );
  }
}
