import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { ListinoService } from 'src/app/_services/listino.service';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil, startWith, map } from 'rxjs/operators';

@Component({
  selector: 'app-internal-confirmation-dialog',
  templateUrl: './internal-confirmation.component.html',
})
export class InternalConfirmationDialogComponent implements OnInit, OnDestroy {
  protected destroyed$ = new Subject();
  internalName = new FormControl('', Validators.required);
  constructor(
    public dialogRef: MatDialogRef<InternalConfirmationDialogComponent>,
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }


  confirm() {
    if (this.internalName.valid) {
      this.dialogRef.close(this.internalName.value);
    }
  }

  dismiss(): void {
    this.dialogRef.close();
  }
}
