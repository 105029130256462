import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap, catchError } from 'rxjs/operators';

import { AuthService } from '../../shared/auth.service';
import { AuthActionTypes, LogIn, LogInSuccess, LogInFailure, StatusSuccess, StatusFailure, GetStatus } from '../actions/auth.actions';


@Injectable()
export class AuthEffects {

  constructor(
    private actions: Actions,
    private authService: AuthService,
    private router: Router,
  ) { }

  // effects go here
  @Effect()
  LogIn: Observable<any> = this.actions
    .pipe(
      ofType(AuthActionTypes.LOGIN),
      map((action: LogIn) => action.payload),
      switchMap(payload => {
        return this.authService.logIn(payload.username, payload.password)
          .pipe(
            map((user) => {
              return new LogInSuccess({ token: user.token, username: payload.username, redirect: payload.redirect });
            }),
            catchError((error) => {
              return of(new LogInFailure({ error }));
            })
          );
      })
    );

  @Effect()
  LogInSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_SUCCESS),
    tap((user: LogInSuccess) => {
      localStorage.setItem('token', user.payload.token);
      if (user.payload.redirect) {
        this.router.navigateByUrl(user.payload.redirect);
      } else {
        this.router.navigateByUrl('/');
      }
    }),
    switchMap(() => of(new GetStatus()))
  );

  @Effect({ dispatch: false })
  LogInFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGIN_FAILURE)
  );

  @Effect({ dispatch: false })
  public LogOut: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.LOGOUT),
    tap((_) => {
      this.authService.removeToken();
      this.router.navigateByUrl('/login');
    })
  );

  @Effect()
  GetStatus: Observable<any> = this.actions
    .pipe(
      ofType(AuthActionTypes.GET_STATUS),
      switchMap(_ => {
        return this.authService.getStatus()
          .pipe(
            map((user) => {
              return new StatusSuccess({ token: this.authService.getToken(), user });
            }),
            catchError((error) => {
              return of(new StatusFailure({ error }));
            })
          );
      })
    );

  @Effect({ dispatch: false })
  StatusSuccess: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.STATUS_SUCCESS)
  );

  @Effect({ dispatch: false })
  StatusFailure: Observable<any> = this.actions.pipe(
    ofType(AuthActionTypes.STATUS_FAILURE)
  );

}
