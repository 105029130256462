import { Observable, of, merge, ReplaySubject, combineLatest } from 'rxjs';
import { Section, SectionItem } from './section-base';
import { FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { startWith, map, filter, takeUntil } from 'rxjs/operators';
import { isString, sortBy } from 'lodash';

export interface ToolsItem extends SectionItem {
    purchasePrice?: number;
    purchaseDiscount?: number;
    markUp?: number;
    uid: string;
    vm: string;
}

export class ToolsSectionHelper extends Section<ToolsItem> {
    addControl = new FormControl();
    protected machines = [];

    get hasTandem() {
      return this.machines.length > 1;
    }

    customItemForm = new FormGroup({
        item: new FormControl('', Validators.required),
        description: new FormControl(''),
        unitPrice: new FormControl(),
        listPrice: new FormControl(),
        vm: new FormControl('')
    });
    // tslint:disable-next-line:variable-name
    protected _showAddNew = false;
    get showAddNew(): boolean {
        return this._showAddNew;
    }

    title: string;
    hasDiscount$ = this.items$
        .pipe(
            map(items => {
                for (const item of items) {
                    if (this.hasCustomDiscount(item)) {
                        return true;
                    }
                }
                return false;
            }),
        );

    protected optionsSource$ = new ReplaySubject<any>(1);
    protected freeAdd = false;

    addOptions$ = combineLatest(
        this.optionsSource$,
        this.addControl.valueChanges.pipe(startWith(''))
    )
        .pipe(
            map(([vps, formValue]) => [vps, formValue ? formValue : '']),
            filter(([_, formValue]) => isString(formValue)),
            map(([vps, formValue]) => {
                formValue = formValue.toLowerCase();
                if (!formValue) {
                    return vps;
                }
                return vps.filter(vp => {
                    const itemName: string = vp.item.toLowerCase();
                    const itemDescription: string = vp.description ? vp.description.toLowerCase() : '';
                    return itemName.includes(formValue) || itemDescription.includes(formValue);
                });
            }),
            map(vps => sortBy(vps, 'item', 'vm')),
            map(vps => {
                return this.freeAdd ? [{ item: 'Manual item', kind: 'add_new' }].concat(vps) : vps;
            })
        );
    // tslint:disable-next-line:variable-name
    protected _items: ToolsItem[] = [];
    constructor(title: string, name: string, freeAdd = true) {
        super(title, name);
        this.freeAdd = freeAdd;
        this.registerRecalculateItems();
        this.hasDiscount$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => { this.updateColumns$.next(); });


    }
    registerRecalculateItems() {
        merge(
            this.form.get('purchaseDiscount').valueChanges.pipe(startWith(30)),
            this.form.get('markUp').valueChanges.pipe(startWith(74)),
        ).pipe(takeUntil(this.destroyed$))
            .subscribe(this.recalculateItems$);
    }
    toggleAddNew() {
        this._showAddNew = !this._showAddNew;
        this.customItemForm.reset();
    }

    setMachines(machines: string[]) {
      this.machines = machines;
      this.updateColumns$.next();
    }

    addItem(item: ToolsItem) {
        this.addControl.patchValue('');
        if (item.kind === 'add_new') {
            this.toggleAddNew();
            return;
        }
        super.addItem(item);
    }

    removeItem(item:ToolsItem);
    removeItem(uid: string);
    removeItem(a: ToolsItem | string) {
        if (typeof a === 'string' ) {
        a = this._items.find(i => i.uid === a);
        }
        super.removeItem(a);
    }

    addNewItem() {
        if (this.customItemForm.invalid) {
            return;
        }

        const item: ToolsItem = {
            toBeDefined: false,
            toCheck: false,
            standard: false,
            kind: 'vp',
            qty: 1,
            note: '',
            price: 0,
            item: '',
            description: '',
            unitPrice: 0,
            listPrice: 0,
            uid: '',
            vm: ''
        };

        item.item = this.customItemForm.get('item').value;
        item.unitPrice = this.customItemForm.get('unitPrice').value;
        item.description = this.customItemForm.get('description').value;
        if (this.customItemForm.get('vm').value) {
          item.vm = this.customItemForm.get('vm').value;
        } else {
          item.vm = this.machines[0];
        }
        item.uid = `${item.vm}_${item.item}`;
        this.toggleAddNew();

        this.addItem(item);
        this.customItemForm.reset();
    }

    /**
     * Aggiorna i dati delle section se comuni tra loro
     * Es: vp condivise tra section... se già scelta in un altra
     * section non posso sceglierla
     * @param items new data
     */
    updateOptionsSource(items: any[]) {
        this.optionsSource$.next(items);
    }


    hasCustomDiscount(item: ToolsItem) {
        return item.markUp !== undefined || item.purchaseDiscount !== undefined;
    }

    setCustomDiscount(item: ToolsItem) {
        if (!this.hasCustomDiscount(item)) {
            const index = this._items.indexOf(item);
            if (index !== -1) {
                const formArr = this.form.get('items') as FormArray;
                const itemGr = formArr.at(index) as FormGroup;
                itemGr.addControl('purchaseDiscount', this.createDiscountForm(30));
                itemGr.addControl('markUp', this.createDiscountForm(74));
            }
        }
    }

    removeCustomDiscount(item: ToolsItem) {
        if (this.hasCustomDiscount(item)) {
            const index = this._items.indexOf(item);
            if (index !== -1) {
                const formArr = this.form.get('items') as FormArray;
                const itemGr = formArr.at(index) as FormGroup;
                itemGr.removeControl('purchaseDiscount');
                itemGr.removeControl('markUp');
            }
        }
    }


    protected createForm() {
        const baseForm = super.createForm();
        baseForm.addControl('purchaseDiscount', new FormControl(30));
        baseForm.addControl('markUp', new FormControl(74));
        baseForm.addControl('vm', new FormControl(''));
        baseForm.addControl('uid', new FormControl(''));
        return baseForm;
    }
    itemToFormGroup(item: ToolsItem) {
        const fg = super.itemToFormGroup(item);
        fg.addControl('vm', new FormControl(item.vm))
        fg.addControl('uid', new FormControl(`${item.vm}_${item.item}`));
        if (item.purchaseDiscount !== undefined && item.markUp !== undefined) {
            fg.addControl('purchaseDiscount', this.createDiscountForm(item.purchaseDiscount));
            fg.addControl('markUp', this.createDiscountForm(item.markUp));
        }
        fg.addControl('purchasePrice', new FormControl(item.purchasePrice));
        return fg;
    }

    clear(vm?: string) {
      if (!vm) {
        super.clear();
        return;
      }
      const filtered = this.items.filter(i => i.vm !== vm && i.item !== vm);
      super.clear();
      for (let item of filtered) {
        this.addItem(item);
      }
    }

    protected compatibilityErrors = [];
    updateRuleErrors(erros: any[]) {
      this.compatibilityErrors = erros;
    }
  
    getError(uid: string) {
      return this.compatibilityErrors.find(i => i.uid === uid);
    }
  
    hasError(uid: string) {
      return !! this.getError(uid);
    }

    protected createDiscountForm(discount) {
        return new FormControl(discount, [Validators.min(0), Validators.max(100)]);
    }

    protected calculateItem(item: ToolsItem, index): ToolsItem {
        // un unico discount applicato a tutti gli item
        const purchaseDiscount = this.form.get('purchaseDiscount').value;
        const markUp = this.form.get('markUp').value;
        const hasDiscount = this.hasCustomDiscount(item);
        const consideredDiscount = hasDiscount ? item.purchaseDiscount : purchaseDiscount;
        const consideredMarkup = hasDiscount ? item.markUp : markUp;

        const listPrice = item.unitPrice * item.qty;
        item.listPrice = listPrice;
        item.purchasePrice = listPrice * (1 - consideredDiscount / 100);
        item.price = item.purchasePrice / (consideredMarkup / 100);
        item.uid = `${item.vm}_${item.item}`;
        this.getItemControl(index, 'listPrice').patchValue(item.listPrice, {emitEvent: false});
        this.getItemControl(index, 'purchasePrice').patchValue(item.purchasePrice, {emitEvent: false});
        this.getItemControl(index, 'price').patchValue(item.price, {emitEvent: false});
        return item;
    }

    protected calcColumns(): Observable<string[]> {
      let columns = ['item', 'description', 'note', 'unitPrice', 'qty', 'listPrice', 'purchasePrice', 'price', 'actions'];
      for (const item of this._items) {
        if (this.hasCustomDiscount(item)) {
          columns.splice(6, 0, 'tools_discount');
          columns.splice(8, 0, 'tools_markup');
        }
      }
      if (this.hasTandem) {
        columns.splice(1, 0, 'vm');
      }

      return of(columns);
    }
    footerRow() {
      return this.hasTandem ? ['item', 'vm', 'description', 'note'] : ['item', 'description', 'note'];
    }
}
