import { AclService } from './../../shared/acl.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { QuotationService } from '../../_services/quotation.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-versions-dialog',
  templateUrl: './quotation-versions.component.html',
  styleUrls: ['./quotation-versions.component.scss']
})
export class VersionsDialogComponent implements OnInit, OnDestroy {
  protected destroyed$ = new Subject();
  versions$ = this.srv.getVersions(this.quotationId);
  newVersionForm = this.fb.group({
    internalName: ['', Validators.required],
    isInternal: false
  });
  isAm$ = this.aclSrv.aclState
    .pipe(
      takeUntil(this.destroyed$),
      map(state => state.hasPermission('am') && !state.hasPermission('quotation'))
    );
  selectedVersion = null;

  constructor(
    protected fb: FormBuilder,
    public dialogRef: MatDialogRef<VersionsDialogComponent>,
    protected srv: QuotationService,
    protected router: Router,
    protected aclSrv: AclService,
    @Inject(MAT_DIALOG_DATA) public quotationId: string) { }

  ngOnInit() {
    this.isAm$
      .subscribe(isAm => {
        if (isAm) {
          this.newVersionForm.get('isInternal').patchValue(true);
          this.newVersionForm.get('isInternal').disable();
        } else {
          this.newVersionForm.get('isInternal').enable();
        }
      })
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  close() {
    this.dialogRef.close(null);
  }

  isCurrent(id: string) {
    return id === this.quotationId;
  }

  createVersion() {
    if (this.newVersionForm.valid) {
      this.srv.createVersion(this.quotationId, this.newVersionForm.getRawValue())
        .subscribe(result => {
          this.openVersion(result._id);
        });
    }
  }

  openVersion(id: string) {
    this.dialogRef.close();
    this.router.navigate(['/quotations', id]);
  }

  hasCaption(version) {
    return this.isCurrent(version._id) || version.isDefault || version.isInternal;
  }

  getCaption(version) {
    const info = [];
    if (this.isCurrent(version._id)) {
      info.push('current');
    }
    if (version.isDefault) {
      info.push('default');
    }
    if (version.isInternal) {
      info.push('internal');
    }
    return `(${info.join(', ')})`;
  }

  selectVersion(version) {
    this.selectedVersion = version._id;
  }

  promoteToDefault() {
    this.versions$ = this.srv.promoteToDefault(this.quotationId, this.selectedVersion)
      .pipe(
        switchMap(() => this.srv.getVersions(this.quotationId))
      );
  }

  isSelected(versionId) {
    return versionId === this.selectedVersion;
  }
}
